import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Checkbox, Divider, Button, Form } from "semantic-ui-react";
import DateRangePicker from 'rsuite/DateRangePicker';
import { useState } from "react";
import moment from "moment/moment";
import axios from "axios";
import { useEffect } from "react";
import { Toast } from "../component/Toast";

export const Lottery = () => {
    const [timeRange, setTimeRange] = useState([
        new Date(moment()),
        new Date(moment().add(7, 'days'))
    ])
    const [minOrder, setMinOrder] = useState(0)
    const [firstLineCode, setFirstLineCode] = useState('')
    const [isActive, setIsActive] = useState(null)

    const handleCheck = (e) => {
        setIsActive(!isActive)
    }

    const getConfigAll = async () => {
        await axios({
            method : "GET",
            url : `${global.SRV}api_rudrunner_cms/controler/event.php/?index=3`,
            Headers : {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (typeof res === 'object' && Object.keys(res).length > 0){
                let data = res.data
                let start = new Date(moment())
                let end = new Date(moment().add(7, 'days'))
                data.forEach(item => {
                    switch (item.name){
                        case "event_lot_fcode":
                            setFirstLineCode(item.nilai)
                            break
                        case "event_lot_min_amount":
                            setMinOrder(item.nilai)
                            break;
                        case "event_lot_active":
                            setIsActive(item.nilai)
                            break
                        case "event_lot_time_start":
                            start = new Date(moment(item.nilai))
                            setTimeRange([start, end])
                            break
                        case "event_lot_time_end":
                            end = new Date(moment(item.nilai))
                            setTimeRange([start, end])
                            break
                        default:break

                    }
                })
            }
        })
    }

    const handleSubmit = () => {
        const data = {
            active : isActive,
            min_order : minOrder,
            time_range : timeRange,
            fcode : firstLineCode,
        }

        axios ({
            method:"POST",
            url : "https://javabasketballasia.com/api_rudrunner_cms/controler/event.php/?index=7",  
            headers : {
                'Content-Type': 'application/json'
            },
            data : data
        })
        .then(res => {
            if (res.data.state === 200){
                Toast.fire({
                    icon:"success",
                    text : "Data updated successfuly",
                    position : "top-right"
                })
            }
        })
    }

    useEffect(() => {
        getConfigAll()
    },[])
   
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Lottery Event</div>
                <div className="text-muted">Customer will get uniq code from their order that meet requirement. </div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={12} md={12} sm={12} >
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field className="mb-3">
                            <label>First Line of Code</label>
                            <Form.Input type="text" value={firstLineCode} onChange={(e) => setFirstLineCode(e.target.value)}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Minimum Order (Exclude Shipping)</label>
                            <Form.Input type="number" value={minOrder} onChange={(e) => setMinOrder(e.target.value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <label>Time Range</label>
                            <DateRangePicker
                                value={timeRange}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="bottomStart"
                                required
                            />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Checkbox label='Active' checked={JSON.parse(isActive)} onChange={(e) => handleCheck(e)}/>
                        </Form.Field>
                        <Button type="submit" content="Submit" fluid color="facebook"/>
                    </Form>
                </Col>
            </Row>

        </Container>
    )
}