import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar'
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsCart, BsBag, BsBox, BsColumnsGap, BsPerson, BsBarChart, BsBookmarkStar, BsGear, BsDoorOpenFill, BsShieldLock } from "react-icons/bs";
import { AiOutlineLogout, AiOutlineLogin, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import 'react-pro-sidebar/dist/css/styles.css';
import { Stack } from "react-bootstrap";


const SideBarMenu = ({ handleCollapsed, collapsed, log, handleLog, access, user }) => {
  const [toggleIcon, setToggleIcon] = useState('')
  console.log(access)
  return (
    <div>
      <ProSidebar collapsed={collapsed} style={{ borderRight: '5px solid #3b5998', height:'100vh' }}>
        <SidebarHeader>
          <Menu iconShape="circle">
            {collapsed ? (
              <MenuItem
                icon={<BsChevronDoubleRight />}
                onClick={handleCollapsed}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<BsChevronDoubleLeft />}
                onClick={handleCollapsed}
              >
                <div
                  style={{
                    textAlign: "left",
                    color: '#33D7FF',
                    textTransform: 'uppercase',
                    fontWeight: 800,
                    fontSize: 15,
                    letterSpacing: '2px'
                  }}
                >
                  RUDRUNNER.CO.ID
                </div>
                <div style={{ fontSize: 10, textAlign: 'left', textTransform: 'uppercase', fontWeight: 500 }}>
                  Content Management System
                </div>
              </MenuItem>
            )}
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            {/* { */}
            {/* access.map((item,i) => ( */}
            <>
              {access.some(i => i.page_name === 'dashboard') && <MenuItem suffix={<Link to={'/'} />} icon={<BsBarChart />}>Dashboard</MenuItem>}
              {
                access.some(i => i.page_name === 'order') && 
                <SubMenu title="Order" icon={<BsCart />}>
                  {access.some(i => i.page_name === 'order') && <MenuItem suffix={<Link to="/order" />}>Transaction</MenuItem>}
                  {access.some(i => i.page_name === 'courier') && <MenuItem suffix={<Link to="/courier" />}>Courier</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'catalog') &&
                <SubMenu title="Catalog" icon={<BsBox />}>
                  {access.some(i => i.page_name === 'product') && <MenuItem suffix={<Link to="/product" />}>Products</MenuItem>}
                  {access.some(i => i.page_name === 'category') && <MenuItem suffix={<Link to="/category" />}>Categories</MenuItem>}
                  {access.some(i => i.page_name === 'color') && <MenuItem suffix={<Link to="/color" />}>Color</MenuItem>}
                  {access.some(i => i.page_name === 'voucher') && <MenuItem suffix={<Link to="/voucher" />}>Voucher</MenuItem>}
                  {access.some(i => i.page_name === 'voucher_special') && <MenuItem suffix={<Link to="/voucher_special" />}>Voucher Special</MenuItem>}
                  {access.some(i => i.page_name === 'sizegroup') && <MenuItem suffix={<Link to="/size_group" />}>Size Group</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'customer_main') &&
                <SubMenu title="Customer" icon={<BsPerson />}>
                  {access.some(i => i.page_name === 'customer') && <MenuItem suffix={<Link to="/customer" />}>Customer</MenuItem>}
                  {access.some(i => i.page_name === 'customer_addr') && <MenuItem suffix={<Link to="/customer_addr" />}>Address</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'layout') &&
                <SubMenu title="Layout" icon={<BsColumnsGap />}>
                  {access.some(i => i.page_name === 'carousel_banner') && <MenuItem suffix={<Link to="/carousel_banner" />}>Carousel Banner</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'event') &&
                <SubMenu title="Event" icon={<BsBookmarkStar />}>
                  {access.some(i => i.page_name === 'flashsale') && <MenuItem suffix={<Link to="/flashsale" />}>Flashsale</MenuItem>}
                  {access.some(i => i.page_name === 'lottery') && <MenuItem suffix={<Link to="/lottery" />}>Lottery</MenuItem>}
                  {access.some(i => i.page_name === 'roulette') && <MenuItem suffix={<Link to="/roulette" />}>Roulette Draw</MenuItem>}
                  {access.some(i => i.page_name === 'raffle') && <MenuItem suffix={<Link to="/raffle" />}>Raffle</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'config') &&
                <SubMenu title="Config" icon={<BsGear />}>
                  {access.some(i => i.page_name === 'config') && <MenuItem suffix={<Link to="/config" />}>Configuration Setup</MenuItem>}
                </SubMenu>
              }
              {
                access.some(i => i.page_name === 'administrator') &&
                <SubMenu title="Administrator" icon={<BsShieldLock />}>
                  {access.some(i => i.page_name === 'employee') && <MenuItem suffix={<Link to="/administrator/employee" />}>Employee</MenuItem>}
                  {access.some(i => i.page_name === 'role_access') && <MenuItem suffix={<Link to="/administrator/role" />}>Access Role</MenuItem>}
                </SubMenu>
              }
            </>
            {/* ))
            } */}
          </Menu>
        </SidebarContent>
        <div className="text-center mb-4" style={{color:'#33D7FF'}}><small>&copy;2024. JFS Developer</small></div>
        <SidebarFooter>
          <Menu iconShape="circle" >
            { user ?
            <MenuItem icon={<AiOutlineUser />}>
              <Stack direction="vertical" gap={0} >
                <small>Hello, {user.name}</small>
                <small>{user.email}</small>
              </Stack>
            </MenuItem>
            : <></>
            }
            <MenuItem icon={<AiOutlineLogout />} onClick={handleLog}>
              Logout
            </MenuItem>
          </Menu>
          {/* {(log === true) ?
            <Menu iconShape="circle">
              <MenuItem icon={<AiOutlineLogout />} onClick={() => handleLog()}>Logout</MenuItem>
            </Menu>
            :
            <Menu iconShape="circle">
              <MenuItem icon={<AiOutlineLogin />} onClick={() => handleLog()}>Login</MenuItem>
            </Menu>
          } */}
        </SidebarFooter>
      </ProSidebar>
    </div>
  )
}

export default SideBarMenu;