import React, {useEffect} from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Divider, Form, Select } from "semantic-ui-react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";


const ProductEdit = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [idGender, setIdGender] = useState([])
    const [idSegment, setIdSegment] = useState([])
    const [genderList, setGenderList] = useState([])
    const [segmentList, setSegmentList] = useState([])
    const [productDesc, setProductDesc] = useState('')
    const [productName, setProductName] = useState('')
    const [category, setCategory] = useState([])
    const [defaultCat, setDefaultCat] = useState([])
    const [selectedCat, setSelectedCat] = useState([])
    const [idProduct, setIdProduct] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState("")
    const [sizeGroup, setSizeGroup] = useState([])
    const [loading, setLoading] = useState(false)


    const handleSubmit = () => {
        if (idProduct && productName && idGender && idSegment && selectedCat){
        setLoading(true)
        let data = {
            id_product : idProduct,
            product_name : productName,
            product_desc : productDesc,
            id_gender : idGender,
            id_segment : idSegment,
            category : selectedCat,
            size_group : selectedGroup
        }
      
        axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=18", data)
        .then(res => {
            if (res.data.state === 200){
               navigate('/product')
               setLoading(false)
            }
        })
        } else {
            alert('Please complete it first before submit..!')
        }
    }

    const handleChangeCat = (e, {value}) => { 
        let valueJson = value.map(i => JSON.parse(i)) 
        setSelectedCat(valueJson)
    }

    const handleChangeSizeGroup = (e, {value}) => {
        setSelectedGroup(value)
    }

    useEffect(() => {
        // POPULATE LIST GENDER
        const getGenderList = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=3")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []
                        data.map(item => (
                            opt.push(
                                {
                                    key: item.id_gender,
                                    value: item.id_gender,
                                    text: item.gender
                                }
                            )
                        ))
                        setGenderList(opt)
                    }
                })
        }
        // POPULATE LIST SEGMENT
        const getSegmentList = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=4")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []
                        data.map(item => (
                            opt.push(
                                {
                                    key: item.id_segment,
                                    value: item.id_segment,
                                    text: item.name
                                }
                            )
                        ))
                        setSegmentList(opt)
                    }
                })
        }
    
        const getCategory =  async () => {
            await axios.get('https://hoopsdevteam.com/api_web/controler/category_rr.php/?index=3')
            .then(res => { 
                if (res.data.length > 0 ) {
                    const option = res.data.map((item,i) => (
                        {
                            key:i,
                            text:(item.child_name) ? item.parent_name + ' > ' + item.child_name : item.parent_name,
                            value: JSON.stringify({id_parent:item.id_parent, id_child:item.id_child}), // UBAH JADI STRING SOALNYA GA BISA OBJECT
                        }
                    ))
                    setCategory(option)
                }
            })
        } 

        const categoryByProduct = async (id) => {
            let data = {
                id_product : id
            }
            await axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=17",data )
            .then(res => { 
                if (res.data.state === 200){
                    setSelectedCat(res.data.data.map((item,i) => (
                        { 
                            id_parent:item.id_category,
                            id_child:item.id_category_child,
                        }
                    )))
                    setDefaultCat(res.data.data.map((item,i) => JSON.stringify(
                        { 
                            id_parent:item.id_category,
                            id_child:item.id_category_child,
                        }
                    )))
                }
            })
        }

        const getSizeGroup = async () => {
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=4`,
                headers: { 'Content-Type': 'application/json' },
            }).
                then(res => {
                    if (res.data.state === 200) {
                        setSizeGroup(res.data.data.map((item,i) => (
                            {
                                key:i,
                                text:item.group_name,
                                value: item.group_name,
                            }
                        )))
                    }
                })
        }

        getGenderList()
        getSegmentList()
        getCategory()
        categoryByProduct(location.state.selected.id_product)
        getSizeGroup()
    }, [])

    useEffect(() => {
        console.log(location.state.selected)
        let idGender = genderList.length > 0 && genderList.find(o => o.text === location.state.selected.gender).value
        let idSegment = segmentList.length > 0 && segmentList.find(o => o.text === location.state.selected.segment_name).value
        
        setIdProduct(location.state.selected.id_product)
        setProductName(location.state.selected.product_name)
        setProductDesc(location.state.selected.product_desc ? location.state.selected.product_desc : '')
        setIdGender(genderList.length > 0 && genderList.find(o => o.text === location.state.selected.gender).value)
        setIdSegment(idSegment) 
        setSelectedGroup(location.state.selected.size_group)
    
    }, [location.state.selected, segmentList])

    console.log(defaultCat)
    return (
        <Container fluid>
            <Row>
                <div className="text-facebook h3 mb-0">Product Edit</div>
                <span className="text-muted">Edit selected product</span>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mt-5 mb-5">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Input label="Product Name" value={productName} onChange={(e, { value }) => setProductName(value)} />
                        <Form.TextArea label='Product Description' value={productDesc} onChange={(e, {value}) => setProductDesc(value)}/>
                        <Form.Select control={Select} value={idGender} options={genderList} label={{ children: 'Gender' }} onChange={(e, {value}) => setIdGender(value)}/>
                        <Form.Select control={Select} value={idSegment} options={segmentList} label={{ children: 'Segment' }} onChange={(e, {value}) => setIdSegment(value)}/>
                        {
                            defaultCat.length > 0 && category.length > 0 ? 
                            <Form.Dropdown 
                                required
                                search
                                control={Select}
                                options={category} 
                                label={{children: 'Category'}} 
                                onChange={handleChangeCat} 
                                multiple 
                                defaultValue={defaultCat}
                            /> 
                            : <></>
                        }
                        <Form.Dropdown
                            required
                            search
                            control={Select}
                            options={sizeGroup}
                            label="Size Group"
                            value={selectedGroup}
                            onChange={handleChangeSizeGroup}
                        />
                        
                        <Form.Button loading={loading} disabled={loading} content="Update" type="submit" color="facebook"/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductEdit