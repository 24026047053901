import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Divider, Form } from "semantic-ui-react";
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { useState } from "react";
import moment from "moment/moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VoucherAdd = () => {
    const navigate = useNavigate()
    const [aloc, setAloc] = useState('')
    const [tipe, setTipe] = useState('')
    const dateNow = new Date();
    const [date, setDate] = useState([
        new Date(),
        new Date(dateNow.setDate(dateNow.getDate() + 7))
    ])
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [code, setCode] = useState('')
    const [value, setValue] = useState(0)
    const [trx, setTrx] = useState(0)
    const [percent, setPercent] = useState(0)
    const [combine, setCombine] = useState('N')
    const [forSpecific, setForSpecific] = useState('N')

    const alocation = [
        {key:'M', text:'Member', value:'M'},
        {key:'C', text:'Customer', value:'C'},
    ]

    const type = [
        {key:'D', text:'Percent', value:'D'},
        {key:'N', text:'Nominal', value:'N'},
        {key: 'O', text: 'Ongkir', value: 'O'}
    ]

    const handleCheckCombine = (e) => { 
        if (e.checked){
            setCombine('Y')
        }else{
            setCombine('N')
        }
    }

    const handleCheckSpecific = (e) => { 
        if (e.checked){
            setForSpecific('Y')
        }else{
            setForSpecific('N')
        }
    }

    const handleSubmit = () => {
        const param = {
            alocation:aloc,
            tipe:tipe,
            date_range:date,
            name:name,
            description:desc,
            voucher_code:code,
            voucher_value:value,
            voucher_percent:percent,
            is_combine:combine,
            for_specific:forSpecific,
            minimum_trx:trx,
        }

        axios({
            method:"POST",
            url:`${global.SRV}api_rudrunner_cms/controler/voucher.php/?index=2`,
            headers:{'Content-Type': 'text/plain'},
            data:param
        })
        .then(res => {
            if (res.data.state === 200){
                navigate('/voucher')
            }
        })
    }
    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0"> Add Voucher</div>
                <div className="text-muted">Adding new voucher</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={8} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field className="mb-3">
                            <Form.Select 
                                label='Alocation'
                                options={alocation}
                                onChange={(e, {value}) => setAloc(value)}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Select
                                label='Type'
                                options={type}
                                onChange={(e, {value}) => setTipe(value)}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Name" value={name} onChange={(e, {value}) => setName(value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Description" onChange={(e, {value}) => setDesc(value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Code" onChange={(e, {value}) => setCode(value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="number" label="Voucher Fixed" defaultValue={0} onChange={(e, {value}) => setValue(value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="number" label="Voucher Percent" defaultValue={0} onChange={(e, {value}) => setPercent(value)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="number" label="Minimum Transaction" defaultValue={0} onChange={(e, {value}) => setTrx(value)}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Checkbox label="Combinable" defaultChecked={false} onChange={(e, data) => handleCheckCombine(data)}/>
                            <Form.Checkbox label="For Specific Product" defaultChecked={false} onChange={(e, data) => handleCheckSpecific(data)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker 
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss" 
                                appearance="default" 
                                size="lg" 
                                style={{width:"100%"}} 
                                placement="topStart"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Save Voucher" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default VoucherAdd