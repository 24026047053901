import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import axios from "axios";
import moment from "moment";


const LoginPage = ({ setLog }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [remember, setRemember] = useState(false)
    const [hiddenMsg, setHiddenMsg] = useState(true)
    const curYear = moment().format('yyyy')
  
    const handleLogin = async () => {
        let param = {
            email: email,
            pwd: pwd,
        }
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/user.php/?index=2`,
            headers: { 'Content-Type': 'application/json' },
            data: param
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200) {
                    localStorage.setItem('uid_rr', res.data.uid)
                    if (remember) {
                        localStorage.setItem('email_rr', res.data.email)
                    }
                    setLog(true)
                }else{
                    setHiddenMsg(false)
                }
            })
    }

    const handleCheck = () => {
        setRemember(!remember)
        localStorage.setItem('remember', !remember)
    }

    useEffect(() => {
        setEmail(localStorage.getItem('email') || '')
        setRemember(localStorage.getItem('remember') === 'true')
    }, [])


    return (
        <Container fluid style={{ height: '100vh' }}>
            <Row className="d-flex align-items-center justify-content-start border" style={{ height: '100%' }}>
                <Col xl={6} lg={6} md={6} sm={12} className="bg-secondary h-100" 
                style={{backgroundImage:"url('https://i.ytimg.com/vi/Eaj5oReZE8M/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGEUgXChlMA8=&rs=AOn4CLAzVZeCmQuIn40-uIWRprflTMqyyw')", backgroundSize:'cover'}}>
                   {/* <Image 
                        src="https://i.ytimg.com/vi/Eaj5oReZE8M/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGEUgXChlMA8=&rs=AOn4CLAzVZeCmQuIn40-uIWRprflTMqyyw"
                   /> */}
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="bg-white h-100">
                    <Row className="d-flex justify-content-center align-items-center h-100">
                        <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                            <Stack direction="vertical" gap={2}>
                            <div className="display-6">www.rudrunner.co.id</div>
                            <div className="fs-5">Content Management System.</div>
                            </Stack>
                        </Col>
                        <Col xl={6} lg={6} md={10} sm={10}>
                            <Message
                                hidden={hiddenMsg}
                                icon='warning'
                                header='Login failed..'
                                content='Wrong email or password.'
                                error
                                onDismiss={()=>setHiddenMsg(true)}
                            />
                            <Form onSubmit={handleLogin}>
                                <Form.Input type="email" label="Email" onChange={(e) => setEmail(e.target.value)} value={email} icon="at"/>
                                <Form.Input type="password" label="Password" onChange={(e) => setPwd(e.target.value)} icon="key"/>
                                <Form.Checkbox label="Remember" className="mb-3" onChange={handleCheck} checked={remember} />
                                <Form.Button
                                    labelPosition="right"
                                    icon="sign in"
                                    color="facebook"
                                    type="submit"
                                    content="Login"
                                    fluid
                                />
                            </Form>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12} className="text-center">
                            @2023 - {curYear}. JFS Developer.
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage;