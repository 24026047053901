import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Image, Table, Stack, Toast } from "react-bootstrap";
import { Divider, Button, Popup, Form, Icon } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import Modal from "rsuite/Modal"
import Reacttable from "../component/Table";

export const OrderReturn = () => {
    const orderData = useLocation().state
    const [prodDet, setProDet] = useState([])
    const [selReturnItem, setSelReturnItem] = useState([])
    const [modalItemList, setModalItemList] = useState(false)
    const [modalQty, setModalQty] = useState(false)
    const [qtyReturn, setQtyReturn] = useState(1)
    const [selRow, setSelRow] = useState(null)
    const [repItem, setRepItem] = useState([])
    const [modalRep, setModalRep] = useState(false)
    const [prodStock, setProdStock] = useState([])
    const [deposit, setDeposit] = useState(0)
    const [repAmount, setRepAmount] = useState(0)

    const columnsItem = useMemo(() => [
        {
            Header: "Product",
            accessor: "id_product",
            Cell: ({ cell }) => (
                <Stack direction="horizontal" gap={3}>
                    <Image src={cell.row.original.image} width={60} height={45} fluid rounded />
                    <Stack direction="vertical">
                        <div className="fw-bold">{cell.row.original.item_name}</div>
                        <div>{cell.row.original.id_product}</div>
                    </Stack>
                </Stack>
            )
        },
        {
            Header: "Size",
            accessor: "size",
        },
        {
            Header: "Price",
            accessor: "price",
        },
        {
            Header: "Qty",
            accessor: "qty",
        },
        {
            Header: "Total",
            accessor: "total_price",
        },
        {
            Header: "Action",
            accessor: "",
            Cell: ({ cell }) => (
                <Popup content="Select this item" size="tiny" trigger={
                    <Button icon="plus" onClick={() => handleSelectItemReturn(cell.row.original)} />
                } />
            )
        },
    ])

    const columnsStock = useMemo(() => [
        {
            Header: 'Image',
            accessor: 'image',
            Cell: ({ cell }) => (<Image src={cell.value} height={40} width={50} fluid rounded />)
        },
        {
            Header: 'ID Product',
            accessor: 'article'
        },
        {
            Header: 'Name',
            accessor: 'product_name',
            Cell: ({ cell }) => (
                <div style={{ width: '200px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )

        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Qty',
            accessor: 'qty'
        },
        {
            Header: 'Action',
            accessor: '',
            Cell: ({ cell }) => (
                <Popup content="Select this product" size="tiny" trigger={
                    <Button icon="plus" size="mini" onClick={() => handleSelectProduct(cell.row.original)} />
                } />
            )
        },
    ])

    const handleSelectProduct = (data) => {
        setRepItem(prev => {
            const itemExists = prev.some(item => item.id === data.id && item.barcode === data.barcode);

            if (!itemExists) {
                return [...prev, data];
            }

            return prev;
        })
        setModalRep(false)
    }

    const getProStock = async () => {
        await axios({
            method: "GET",
            url: `https://hoopsdevteam.com/api_web/controler/product_rr.php/?index=24`,
        }).then(res => {
            if (res.data.state === 200) {
                const filteredData = res.data.data.filter(item => {
                    const exist = repItem.some(r => r.article === item.article && r.barcode === item.barcode)
                    if (!exist){
                        return item
                    }
                })
                setProdStock(filteredData)
            }
        })
    }

    const getProdDet = async (idOrder) => {
        await axios({
            method: "GET",
            url: `${global.SRV}/api_rudrunner_cms/controler/order.php/?index=12&id_order=${idOrder}`,
        }).then(res => {
            if (res.data.state === 200) {
                setProDet(res.data.data)
            }
        })
    }

    const handleRemoveRep = (data) => {
        setRepItem(repItem.filter(i => i.article !== data.article))
    }

    const submitQty = () => {
        setSelReturnItem(prevItems => {
            const itemExists = prevItems.some(item => item.id === selRow.id && item.barcode === selRow.barcode)
            if (itemExists) {
                alert('Item has been added')
                return prevItems
            }

            if (qtyReturn > selRow.qty) {
                alert('Over Quantity')
                return prevItems
            }

            const newItem = {
                ...selRow,
                qty_return: qtyReturn,
                total_return: qtyReturn * selRow.price
            }

            setModalQty(false)
            setModalItemList(false)

            return [...prevItems, newItem]
        })
        setQtyReturn(1)
    }

    const handleSelectItemReturn = (data) => {
        setSelRow(data)
        setModalQty(true)
    }

    const handleRemoveItemReturn = (item) => {
        const newData = selReturnItem.filter(sel => sel.id_product !== item.id_product)
        setSelReturnItem(newData)
    }

    const handleAddItem = () => {
        setModalItemList(true)
    }

    const handleClickReplace = () => {
        setModalRep(true)
    }
    
    useEffect(() => {
        const sum = repItem.reduce((total, cur) => total + parseFloat(cur.final_price),0)
        console.log(sum)
    }, [repItem])

    useEffect(() => {
        const sum = selReturnItem.reduce((total, cur) => total + parseFloat(cur.total_return),0)
        setDeposit(sum)
    },[selReturnItem])

    useEffect(() => {
        getProStock()
    },[repItem])

    useEffect(() => {
        getProdDet(orderData.id_order)
    }, [orderData])

    console.log(repItem)
    return (
        <>
            <Container fluid>
                <Row className="mt-1 mb-3">
                    <div className="text-facebook h3 mb-0">Order Return</div>
                    <div className="text-muted">This order will be return #{orderData.id_order}</div>
                </Row>
                <Divider />
                <Row>
                    <Col xl={12} lg={12}>
                        <div className="h5">List Product to Return from Order : #{orderData.id_order}</div>
                        <Table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selReturnItem?.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ width: '2em' }}>{i += 1}</td>
                                            <td>
                                                <Stack direction="horizontal" gap={2}>
                                                    <Image src={item.image} width={65} height={50} fluid rounded />
                                                    <Stack direction="vertical">
                                                        <div className="fw-bold">{item.item_name}</div>
                                                        <div>{item.id_product}</div>
                                                    </Stack>
                                                </Stack>
                                            </td>
                                            <td>{item.price}</td>
                                            <td>{item.qty_return}</td>
                                            <td>{item.total_return}</td>
                                            <td>
                                                <Popup content="Remove" size="tiny" trigger={
                                                    <Button icon="close" size="tiny" onClick={() => handleRemoveItemReturn(item)} />
                                                } />
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan={3}></td>
                                    <td colSpan={3} className="text-end">
                                        <Button icon="plus" content="Add New Item" size="tiny" onClick={handleAddItem} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xl={12} lg={12}>
                        <div className="h5 mb-3">Select Replacement Product</div>
                        <Row>
                            {
                                repItem.map((item, i) => (
                                    <Col xl={2} lg={3} md={4} sm={6} xs={1} className="d-flex justify-content-center">
                                        <div style={{ height: '280px', width: '180px', borderRadius: '10px', border: '1px solid lightgrey', overflow: 'hidden', }}>
                                            <Image src={item.image} fluid />
                                            <div className="ps-2 pe-2">
                                                <div className="text-truncate" style={{ fontSize: '0.8em' }}>{item.product_name}</div>
                                                <div style={{ fontSize: '0.8em' }}>{item.article}</div>
                                                <div>{item.final_price}</div>
                                                <div>Size : {item.size}</div>
                                                <div className="mt-2">
                                                    <Popup content="Remove this item" size="mini" trigger={
                                                        <Icon name="close" onClick={() => handleRemoveRep(item)} />
                                                    } />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                            <Col xl={2} lg={3} md={4} sm={6} xs={1} className="d-flex justify-content-center">
                                <div style={{ height: '280px', width: '180px', borderRadius: '10px', border: '2px dashed lightgrey', cursor: 'pointer' }} onClick={handleClickReplace}>
                                    <div className="d-flex align-items-center justify-content-center h-100">
                                        <Icon name="plus" size="large" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal open={modalItemList} onClose={() => setModalItemList(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Product Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Item from order : #{orderData.id_order}</div>
                    <Reacttable data={prodDet} columns={columnsItem} pageRow={5} />
                </Modal.Body>
            </Modal>
            <Modal open={modalQty} onClose={() => setModalQty(false)} overflow={false} size="xs" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmQty" onSubmit={submitQty}>
                        <Form.Input label="Return Qty" value={qtyReturn} onChange={(e) => setQtyReturn(e.target.value)} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" type="submit" form="frmQty" />
                </Modal.Footer>
            </Modal>
            <Modal open={modalRep} onClose={() => setModalRep(false)} backdrop="static" size="lg" overflow={false}>
                <Modal.Header>
                    <Modal.Title>Product List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={prodStock} columns={columnsStock} pageRow={8} />
                </Modal.Body>
            </Modal>
        </>
    )
}