import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Container, Row, Col, Stack, Alert, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Button, Divider, Form, Icon, Label, Popup } from "semantic-ui-react";
import axios from "axios";
import Table from "../component/Table";

const ProductImage = () => {
    const location = useLocation()
    const [selectedImg, setSelectedImg] = useState(null)
    const [responseArray, setResponseArray] = useState([])
    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        setSelectedImg(e.target.files)
        setResponseArray([])
    }

    const handleSetDef = (data, id_product) => {
        const param = { id_image: data.id_image, id_product: id_product }

        axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=23", param)
            .then(res => {
                if (res.data.state === 200) {
                    getData(id_product)
                }
            })
    }

    const handleDeleteImg = (data, id_product) => {
        const param = { id_image: data.id_image }
        if (window.confirm("Are you sure?")) {
            axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=35", param)
                .then(res => {
                    if (res.data.state === 200) {
                        getData(id_product)
                    }
                })
        }

    }

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_image"
        },
        {
            Header: "Image",
            accessor: "image",
            Cell: ({ cell }) => (
                (cell.value !== "") ? <Image src={cell.value} width={35} height={25} rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={35} height={25} rounded />
            )
        },
        {
            Header: "Default",
            accessor: "is_default",
            Cell: ({ cell }) => (
                (cell.value === "1") ? <Label size="mini">Default</Label> : <></>
            )
        },
        {
            Header: "Action",
            Cell: ({ cell }) => (
                <div className="d-flex justify-content-end">
                    <Fragment>
                        <Button.Group size="mini" basic>
                            <Popup content="Set default" trigger={<Button icon="paperclip" onClick={() => handleSetDef(cell.row.values, location.state.selected.id_product)} />} size="mini" />
                            <Popup content="Delete" trigger={<Button icon="eraser" onClick={() => handleDeleteImg(cell.row.values, location.state.selected.id_product)} />} size="mini" />
                        </Button.Group>
                    </Fragment>
                </div>
            )
        }

    ], [])

    const getData = async (id) => {
        await axios.get(`https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=13&id_product=${id}`)
            .then(res => {
                if (res.data.state === 200) {
                    setData(res.data.data)
                }
            })
    }

    const handleSubmit = (e) => {
        const endPoint = "https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=5"
        const data = new FormData()

        if (!selectedImg) {
            alert('Hei 🤨, select one or more image bro...')
            return false
        }

        for (let i = 0; i < selectedImg.length; i++) {
            data.append("file[]", selectedImg[i])
        }
        data.append("id_product", location.state.selected.id_product)

        axios.post(endPoint, data)
            .then(res => {
                console.log(res.data)
                setResponseArray(res.data)
                setSelectedImg(null)
                resetFile()
                getData(location.state.selected.id_product)
            }, error => {
                alert(error)
            })
    }

    const resetFile = () => {
        document.getElementsByTagName("input")[0].value = null;
    }

    useEffect(() => {
        getData(location.state.selected.id_product)
    }, [location.state])

    return (
        <Container fluid={true}>
            <Row className="mt-1">
                <div className="text-facebook h3 mb-0">Product Image</div>
                <small>
                    <span className="text-muted fw-bold">{location.state.selected.id_product} - </span>
                    <span className="fw-bold">{location.state.selected.product_name}</span>
                </small>
            </Row>
            <Divider />

            <Row className="d-flex justify-content-center mb-3">
                <Col xl={6} lg={10} md={12} sm={12}>
                    {
                        (responseArray && responseArray.length > 0) &&
                        responseArray.map((res, i) => (
                            <div key={i}>
                                <Alert variant={res.status} dismissible>
                                    <Stack direction="horizontal" gap={3}>
                                        <div><Image src={res.url} width={60} height={50} /></div>
                                        <div>{res.message}</div>
                                    </Stack>
                                </Alert>
                            </div>
                        ))}
                </Col>
            </Row>
            <Row className="mb-3 justify-content-center">
                <Col md={12} lg={12} xl={6} className="mb-5">
                    <div className="h5 mb-4"><Icon name="add" className="me-3" />New Image</div>
                    <Form widths="equal" onSubmit={(e) => handleSubmit(e)}>
                        <Form.Input
                            label="Images"
                            multiple
                            type="file"
                            icon="images"
                            onChange={(e) => handleInputChange(e)}
                        />
                        <div><small>Press <span className="fw-bold">Ctrl</span> key on keyboard to select multiple images</small></div>
                        <Button content="Upload Image" type="submit" className="mt-4" color="facebook" />
                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={12} lg={12} xl={6} className="mb-5">
                    <div className="h5 mb-4"><Icon name="images outline" className="me-3" />Image that has been added</div>
                    <Table columns={columns} data={data} />
                </Col>
            </Row>
        </Container>
    )
}

export default ProductImage