import React, { useState, useEffect, Fragment } from "react";
import Chart from "react-apexcharts";
import { Card, Col, Image, ListGroup, Modal, Row, Spinner, Stack } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import moment from 'moment-timezone';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { Dimmer, Dropdown, Icon, Label, Loader, Popup, Button } from "semantic-ui-react";

const Dashboard = () => {
    const dateNow = moment();
    const timeZone = 'Asia/Jakarta';
    const [currentUser, setCurrentUser] = useState(0)
    const [users, setUsers] = useState(0)
    const [newUser, setNewUser] = useState(0)
    const [userEngage, setUserEngage] = useState('')
    const [userSource, setUserSource] = useState(null)
    const [userCity, setUserCity] = useState([])
    const [userVsNew, setUserVsNew] = useState(null)
    const [userPerHour, setUserPerHour] = useState([])
    const [totalUser, setTotalUser] = useState(0)
    const [totalUserNew, setTotalUserNew] = useState(0)
    const [mostLoyal, setMostLoyal] = useState(null)
    const [showOrder, setShowOrder] = useState(false)
    const [selDateOrder, setSelDateOrder] = useState('')
    const [orderData, setOrderData] = useState([])
    const [selCustomer, setSelCustomer] = useState({})
    const [productCustomer, setProductCustomer] = useState([])
    const [showProduct, setShowProduct] = useState(false)
    const [loaderActive, setLoaderActive] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [orderDetail, setOrderDetail] = useState(null)
    const [modalOrderDetail, setModalOrderDetail] = useState(false)
    const [loadDetailOrder, setLoadDetailOrder] = useState(false)


    const [datePay, setDatePay] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateLoyal, setDateLoyal] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateSoldPrd, setDateSoldPrd] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateSales, setDateSales] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateSource, setDateSource] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateCity, setDateCity] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [dateUserVsNew, setDateUserVsNew] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [date, setDate] = useState([
        dateNow.startOf('month').tz(timeZone).toDate(),
        dateNow.endOf('month').tz(timeZone).toDate()
    ])
    const [selYear, setSelYear] = useState(dateNow.year())
    const [salesData, setSalesData] = useState(null)
    const [totalSalesData, setTotalSalesData] = useState('')
    const [mostProdSales, setMostProdSales] = useState(null)
    const [salesByMonthData, setSalesByMonthData] = useState(null)
    const [mostPayMethod, setMostPayMethod] = useState(null)

    const yearGenerator = (startYear, endYear) => {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push({
                key: year,
                text: year.toString(),
                value: year,
            });
        }
        return years;
    }

    const handleCloseOrder = () => (setShowOrder(false))
    const handleCloseProduct = () => (setShowProduct(false))

    const handleShowOrder = (tgl) => {
        setShowOrder(true)
        setSelDateOrder(tgl)
    }

    const handleChangeDateMostLoyal = (e) => {
        setMostLoyal(null)
        getMostLoyalCustomer(e)
        setDateLoyal(e)
    }

    const handleChangeDatePay = (e) => {
        setMostPayMethod(null)
        getMostPaymentMethod(e)
        setDatePay(e)
    }

    const handleChangeDateUserSource = (e) => {
        setUserSource(null)
        setDateSource(e)
        getUserSource(e)
    }

    const handleChangeDateUserCity = (e) => {
        setDateCity(e)
        getUserCity(e)
    }

    const handleChangeDateUserVsNew = (e) => {
        setUserVsNew(null)
        getUserVsNew(e)
        setDateUserVsNew(e)
    }

    const handleChangeSoldPrd = (e) => {
        setMostProdSales(null)
        setDateSoldPrd(e)
        getMostProductSales(e)
    }

    const handleChangeRevenue = (e) => {
        setSalesByMonthData(null)
        setSelYear(e.value)
        getSalesByMonth(e.value)
    }

    const handleChangeSales = (e) => {
        setSalesData(null)
        setDateSales(e)
        getSalesData(e)
    }

    const getSalesData = async (dateRange) => {
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/dashboard.php/?index=1`,
            headers: { 'Content-Type': 'application/json' },
            data: dateRange
        })
            .then(res => {
                if (res.data.state === 200) {
                    setSalesData(res.data.data)
                    setTotalSalesData(res.data.total_revenue_with_shipping)
                }
            })
    }

    const getMostProductSales = async (dateRange) => {
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/dashboard.php/?index=2`,
            headers: { 'Content-Type': 'application/json' },
            data: dateRange
        })
            .then(res => {
                if (res.data.state === 200) {
                    setMostProdSales(res.data.data)
                }
            })
    }

    const getSalesByMonth = async (year) => {
        const param = new URLSearchParams()
        param.append("year", year)

        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/dashboard.php/?index=3`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setSalesByMonthData(res.data.data)
                }
            })
    }

    const getMostPaymentMethod = async (dateRange) => {
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/dashboard.php/?index=4`,
            headers: { 'Content-Type': 'application/json' },
            data: dateRange
        })
            .then(res => {
                if (res.data.state === 200) {
                    setMostPayMethod(res.data.data)
                }
            })
    }

    const getCurrentUser = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=5`,
        })
            .then(res => {
                setCurrentUser(res.data)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }

    const getUsers = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=0`,
        })
            .then(res => {
                setUsers(res.data)
            })
    }

    const getNewUser = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=1`,
        })
            .then(res => {
                setNewUser(res.data)
            })
    }

    const getUserEnggage = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=4`,
        })
            .then(res => {
                setUserEngage(res.data)
            })
    }

    const getUserSource = async (dateRange) => {
        let date1 = new Date(dateRange[0])
        let date2 = new Date(dateRange[1])
        let start = moment(date1).format('YYYY-MM-DD');
        let end = moment(date2).format('YYYY-MM-DD');

        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=6&start=${start}&end=${end}`,
            data: dateRange
        })
            .then(res => {
                setUserSource(res.data)
            })
    }

    const getUserCity = async (dateRange) => {
        let date1 = new Date(dateRange[0])
        let date2 = new Date(dateRange[1])
        let start = moment(date1).format('YYYY-MM-DD');
        let end = moment(date2).format('YYYY-MM-DD');

        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=12&start=${start}&end=${end}`,
            data: dateRange
        })
            .then(res => {
                setUserCity(res.data)
            })
    }

    const getUserVsNew = async (dateRange) => {
        let date1 = new Date(dateRange[0])
        let date2 = new Date(dateRange[1])
        let start = moment(date1).format('YYYY-MM-DD');
        let end = moment(date2).format('YYYY-MM-DD');
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=13&start=${start}&end=${end}`,
            data: dateRange
        })
            .then(res => { 
                setUserVsNew(res.data)
                setTotalUser(res.data.total_user)
                setTotalUserNew(res.data.total_user_new)
            })
    }

    const getUserPerHour = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/ga4/controlerAnalitycs.php?index=14`,
        })
            .then(res => {
                setUserPerHour(res.data)
            })
    }

    const getMostLoyalCustomer = async (dateRange) => {
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/dashboard.php/?index=5`,
            headers: { 'Content-Type': 'application/json' },
            data: dateRange
        })
            .then(res => {
                if (res.data.state === 200) {
                    setMostLoyal(res.data.data)
                }
            })
    }

    const getOrderDetail = async (date) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/order.php/?index=10&date=${date}`,
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => {
                if (res.data.state === 200) {
                    setOrderData(res.data.data)
                }
            })
    }

    const getOrderProductByLoyalCustomer = async (id, start, end) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/order.php/?index=11&id_customer=${id}&start=${start}&end=${end}`,
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => {
                if (res.data.state === 200) {
                    setProductCustomer(res.data.data)
                    setOrderTotal(res.data.order_total)
                    setTotalQty(res.data.total_qty)
                }
            })
    }

    const getDetail = async (id_order, id_customer) => {
        setLoadDetailOrder(true)
        let param = {
            id_order: id_order,
            id_customer: id_customer
        }

        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/order.php/?index=2`,
            headers: { 'Content-Type': 'application/json' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setOrderDetail(res.data.data)
                    setLoadDetailOrder(false)
                }
            })
    }

    const handleClickOrder = (idOrder, idCustomer) => {
        getDetail(idOrder, idCustomer)
        setModalOrderDetail(true)
        console.log(orderDetail)
    }

    useEffect(() => {
        getSalesData(date)
        getMostProductSales(date)
        getMostPaymentMethod(datePay)
        yearGenerator(2023, 2030)
        getUsers()
        getNewUser()
        getUserEnggage()
        getUserSource(date)
        getUserCity(date)
        getUserVsNew(dateUserVsNew)
        getMostLoyalCustomer(dateLoyal)
        // getUserPerHour()
    }, [])

    useEffect(() => {
        getSalesByMonth(selYear)
    }, [selYear])

    useEffect(() => {
        getOrderDetail(selDateOrder)
    }, [selDateOrder])

    useEffect(() => {
        let start = moment(dateLoyal[0]).format('YYYY-MM-DD')
        let end = moment(dateLoyal[1]).format('YYYY-MM-DD')

        getOrderProductByLoyalCustomer(selCustomer.id, start, end)
    }, [selCustomer])

    useEffect(() => {
        getCurrentUser()
        const intervalId = setInterval(function () {
            getCurrentUser();
        }, 10000);
        return () => clearInterval(intervalId);
    }, [])
    console.log(orderDetail)
    return (
        <>
            <div className="fw-bold fs-5 ms-3">Dashboard</div>
            <div className="m-3">
                { orderDetail ?
                    <Modal show={modalOrderDetail} onHide={() => setModalOrderDetail(false)} centered style={{ fontSize: '0.9em' }}>
                        <Modal.Header closeButton>
                            <Modal.Title className="fs-5">Products from order : <span style={{ fontSize: '0.9em' }}>#{orderDetail.customer.id_order}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Dimmer active={loadDetailOrder} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <div className="mb-3">
                                <div className="fs-5 fw-bold text-capitalize">{orderDetail.customer.customer_name}</div>
                                <div>{orderDetail.customer.email}</div>
                            </div>
                            {
                                orderDetail.order_detail?.map((item, i) =>
                                    <Stack direction="horizontal" gap={3} className="mb-3">
                                        <Image src={item.image} width={100} height={90} rounded className="border" />
                                        <Stack direction="vertical" style={{ width: '300px' }}>
                                            <div>{item.id_product}</div>
                                            <div className="fw-bold text-truncate">{item.item_name}</div>
                                            <div>
                                                <span className="fw-bold me-4">{global.IDR.format(item.total_price)}</span>
                                                {
                                                    item.product_detail.isDiscounted === '1' ?
                                                    <span><s>{global.IDR.format(item.price)}</s></span>
                                                    : <></>
                                                }
                                            </div>
                                            <div>
                                                <span className="me-3">Size : {item.size}</span>
                                                <span>Qty : {item.qty}</span>
                                            </div>
                                        </Stack>
                                    </Stack>
                                )
                            }
                        </Modal.Body>
                    </Modal>
                    : <></>
                }
                <Modal show={showOrder} onHide={handleCloseOrder} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className="fs-5">Order Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="mb-3">Date : {selDateOrder}</div>
                        <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                            <table className="table">
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Order</th>
                                        <th>Customer</th>
                                        <th>Courier</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.9em' }}>
                                    {
                                        orderData.map((item, i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.id_order}</td>
                                                <td>
                                                    <Stack direction="vertical" gap={0}>
                                                        <div className="fw-bold text-capitalize">{item.first_name + ' ' + item.last_name}</div>
                                                        <div>{item.email}</div>
                                                    </Stack>
                                                </td>
                                                <td>
                                                    <Stack direction="vertical" gap={0}>
                                                        <div className="">{item.name} {item.id_service}</div>
                                                        <div>{item.total_weight}Kg - {global.IDR.format(item.courier_cost)}</div>
                                                    </Stack>
                                                </td>
                                                <td>{global.IDR.format(item.total_payment)}</td>
                                                <td>
                                                    <Popup
                                                        content="View Products"
                                                        trigger={
                                                            <Button icon="box" size="tiny" basic onClick={() => handleClickOrder(item.id_order, item.id_customer)} />
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showProduct} onHide={handleCloseProduct} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title className="fs-5">Product Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Dimmer active={loaderActive} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <div className="mb-3">
                            <span className="me-2">{selCustomer.nama}</span>
                            <span><Label content={selCustomer.member} size="mini" color="orange" /></span></div>
                        <table className="table">
                            <thead className="bg-light">
                                <tr>
                                    <td>#</td>
                                    <td>Order</td>
                                    <td>Product</td>
                                    <td>Price</td>
                                    <td>Shipping</td>
                                    <td>Total</td>
                                    <td>Size</td>
                                    <td>Qty</td>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '0.8em' }}>
                                {
                                    productCustomer.map((item, i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <Stack direction="vertical" gap={0}>
                                                    <div className="fw-bold">{item.id_order}</div>
                                                    <div>{item.date}</div>
                                                </Stack>
                                            </td>
                                            <td>
                                                <Stack direction="horizontal" gap={2}>
                                                    <Image className="border" rounded src={item.image} width={45} height={40} />
                                                    <Stack direction="vertical" gap={0} style={{ width: 150 }}>
                                                        <Popup trigger={<div className="text-truncate fw-bold">{item.item_name}</div>} content={item.item_name} size="mini" />
                                                        <div>{item.id_product}</div>
                                                    </Stack>
                                                </Stack>
                                            </td>
                                            <td>{global.IDR.format(item.total_price)}</td>
                                            <td>{global.IDR.format(item.courier_cost)}</td>
                                            <td>{global.IDR.format(item.total_payment)}</td>
                                            <td>{item.size}</td>
                                            <td>{item.qty}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr style={{ fontSize: '0.9em' }}>
                                    <td colSpan={6} className="text-end">Total Spend : <span className="fw-bold">{global.IDR.format(orderTotal)}</span></td>
                                    <td colSpan={2} className="text-end">Total Qty : <span className="fw-bold">{totalQty}</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </Modal.Body>
                </Modal>
                <Row>
                    <Col lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <label>Online User</label>
                                <p className="fs-5">{currentUser > 0 ? currentUser : 'calculating..'}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <label>User this month</label>
                                <p className="fs-5">{users > 0 ? users : 'calculating..'}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <label>New User this month</label>
                                <p className="fs-5">{newUser > 0 ? newUser : 'calculating..'}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <label>User Engagement (Avg.)</label>
                                <p className="fs-5">{userEngage !== '' ? userEngage : 'calculating..'}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">Sales Data</Card.Header>
                            <Card.Body style={{ height: 450 }}>
                                <DateRangePicker
                                    defaultValue={dateSales}
                                    value={dateSales}
                                    placement="autoVerticalStart"
                                    cleanable={true}
                                    onOk={handleChangeSales}
                                />

                                {
                                    salesData ?
                                        <>
                                            <ReactApexChart
                                                type="bar"
                                                options={
                                                    {
                                                        chart: {
                                                            type: "bar",
                                                            events: {
                                                                dataPointSelection: (event, chartContext, config) => {
                                                                    const { dataPointIndex, seriesIndex } = config;
                                                                    const clickedValue = salesData[dataPointIndex];
                                                                    const tgl = moment(clickedValue.order_date).format('YYYY-MM-DD')
                                                                    handleShowOrder(tgl)
                                                                }
                                                            }
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                borderRadius: 0,
                                                                horizontal: false,
                                                            },
                                                        },
                                                        xaxis: {
                                                            categories: salesData.map(order => {
                                                                let order_date = new Date(order.order_date)
                                                                return order_date.toLocaleDateString('in-US', { weekday: 'long', month: 'long', day: 'numeric' })
                                                            }),
                                                        },
                                                        yaxis: {
                                                            labels: {
                                                                formatter: function (val) {
                                                                    return global.IDR.format(val);
                                                                },
                                                            },
                                                        },
                                                        tooltip: {
                                                            y: {
                                                                formatter: function (val) {
                                                                    return global.IDR.format(val);
                                                                },
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false,
                                                            formatter: function (val) {
                                                                return global.IDR.format(val);
                                                            },
                                                        },
                                                    }
                                                }
                                                series={[{
                                                    data: salesData.map(order => parseInt(order.total_payment))
                                                }]}
                                                height={350}
                                            />
                                            <div>Total sales : {global.IDR.format(totalSalesData)}</div>
                                        </>
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">Most Buy Products</Card.Header>
                            <Card.Body style={{ height: 380 }}>
                                <DateRangePicker
                                    defaultValue={dateSoldPrd}
                                    value={dateSoldPrd}
                                    placement="autoVerticalStart"
                                    cleanable={true}
                                    onOk={handleChangeSoldPrd}
                                />

                                {
                                    mostProdSales ?
                                        <>
                                            <ReactApexChart
                                                type="bar"
                                                options={
                                                    {
                                                        chart: {
                                                            type: "bar",
                                                            height: 350,
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                borderRadius: 0,
                                                                horizontal: false,
                                                                barHeight: '80%',
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                        tooltip: {
                                                            enabled: true,
                                                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                                const data = mostProdSales[dataPointIndex];
                                                                const imagePath = data.image;
                                                                const imageWidth = 50;
                                                                const imageHeight = 50;

                                                                return `<div style="text-align: center;font-size:0.7em">
                                                                        <img src="${imagePath}" width="${imageWidth}" height="${imageHeight}" />
                                                                        <div>${data.id_product}</div>
                                                                        <div>Qty : ${series[seriesIndex][dataPointIndex]}</div>
                                                                    </div>`;
                                                            }
                                                        },
                                                        xaxis: {
                                                            enabled: false,
                                                            type: "category",
                                                            categories: mostProdSales.map(order => {
                                                                return order.id_product
                                                            })
                                                        },
                                                        yaxis: {
                                                            show: true,
                                                        }
                                                    }
                                                }
                                                series={[{
                                                    data: mostProdSales.map(order => parseInt(order.qty))
                                                }]}
                                                height={284}
                                            />
                                            <div>Total product sold : {
                                                mostProdSales.reduce((a, c) => a + parseInt(c.qty), 0)
                                            }</div>
                                        </>
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">Total Revenue by Month</Card.Header>
                            <Card.Body style={{ height: 380 }}>
                                <Dropdown
                                    defaultValue={dateNow.year()}
                                    options={yearGenerator(2023, 2030)}
                                    selection
                                    onChange={(e, value) => handleChangeRevenue(value)}
                                />

                                {
                                    salesByMonthData ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    chart: {
                                                        type: "bar",
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 1,
                                                            horizontal: false
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    xaxis: {
                                                        categories: salesByMonthData.map(order => {
                                                            return order.month_formated
                                                        })
                                                    },
                                                    yaxis: {
                                                        show: false
                                                    },
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function (val) {
                                                            return global.IDR.format(val);
                                                        },
                                                    },
                                                    tooltip: {
                                                        y: {
                                                            formatter: function (val) {
                                                                return global.IDR.format(val);
                                                            },
                                                        },
                                                    },
                                                }
                                            }
                                            series={[
                                                {
                                                    data: salesByMonthData.map(order => parseInt(order.total_order))
                                                }
                                            ]}
                                            height={300}
                                        />
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">Most Used Payment Method</Card.Header>
                            <Card.Body style={{ height: 300 }}>
                                <DateRangePicker
                                    defaultValue={datePay}
                                    value={datePay}
                                    placement="autoVerticalStart"
                                    cleanable={true}
                                    onOk={handleChangeDatePay}
                                />

                                {
                                    mostPayMethod ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: mostPayMethod.map(order => {
                                                        return order.payment_method
                                                    }),
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%'
                                                            },
                                                            legend: {
                                                                show: false
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 0,
                                                        height: '50%',
                                                        width: 250
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            series={mostPayMethod.map(order => parseInt(order.method))}
                                            height={220}
                                        />
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">User By Source</Card.Header>
                            <Card.Body style={{ height: 300 }}>
                                <DateRangePicker
                                    defaultValue={dateSource}
                                    value={dateSource}
                                    placement="auto"
                                    cleanable={true}
                                    onOk={handleChangeDateUserSource}
                                />
                                {
                                    userSource ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: userSource.label,
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: false,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 0,
                                                        height: '100%',
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            series={userSource.value}
                                            height={220}
                                        />
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8} className="mb-4">
                        <Card>
                            <Card.Header>Users</Card.Header>
                            <Card.Body style={{ height: 380 }}>
                                <DateRangePicker
                                    className="mb-3"
                                    defaultValue={dateUserVsNew}
                                    value={dateUserVsNew}
                                    placement="auto"
                                    cleanable={true}
                                    onOk={handleChangeDateUserVsNew}
                                />
                                {
                                    userVsNew ?
                                        <>
                                            <ReactApexChart
                                                type="area"
                                                options={
                                                    {
                                                        chart: {
                                                            id: 'dual-area-chart',
                                                        },
                                                        xaxis: {
                                                            categories: userVsNew?.user.map(i => i.date),
                                                        },
                                                        stroke: {
                                                            curve: 'straight',
                                                            width: 2
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                    }
                                                }
                                                series={
                                                    [
                                                        {
                                                            name: "Users",
                                                            data: userVsNew?.user.map(i => i.user),
                                                        },
                                                        {
                                                            name: "New Users",
                                                            data: userVsNew?.user_new?.map(i => i.user),
                                                        },
                                                    ]
                                                }
                                                height={250}
                                            />
                                            <div className="mb-3">
                                                <span className="me-4">Total User : {totalUser}</span>
                                                <span>Total New User : {totalUserNew}</span>
                                            </div>
                                        </>
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">User By City</Card.Header>
                            <Card.Body style={{ height: 380 }}>
                                <DateRangePicker
                                    className="mb-3"
                                    defaultValue={dateCity}
                                    value={dateCity}
                                    placement="auto"
                                    cleanable={true}
                                    onOk={handleChangeDateUserCity}
                                />
                                <div style={{ maxHeight: "250px", overflowY: "auto" }} className="pe-2">
                                    <ListGroup variant="flush">
                                        {
                                            userCity.map((city, i) => {
                                                return (
                                                    <ListGroup.Item
                                                        as="li"
                                                        className="d-flex justify-content-between align-items-start"
                                                    >
                                                        {city.city}
                                                        <Label content={city.value} size="mini" color="blue" />
                                                    </ListGroup.Item>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Card>
                            <Card.Header className="bg-light">Most Loyal Customer</Card.Header>
                            <Card.Body style={{ height: 380 }}>
                                <DateRangePicker
                                    className="mb-3"
                                    defaultValue={dateLoyal}
                                    value={dateLoyal}
                                    placement="auto"
                                    cleanable={true}
                                    onOk={handleChangeDateMostLoyal}
                                />

                                {
                                    mostLoyal ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    chart: {
                                                        type: "bar",
                                                        events: {
                                                            dataPointSelection: (event, chartContext, config) => {
                                                                const { dataPointIndex, seriesIndex } = config
                                                                const id = mostLoyal[dataPointIndex].id_customer
                                                                const nama = mostLoyal[dataPointIndex].name
                                                                const is_member = mostLoyal[dataPointIndex].isMember
                                                                setSelCustomer({
                                                                    id: id,
                                                                    nama: nama,
                                                                    member: is_member == 'Y' ? 'Member' : 'Customer',
                                                                })
                                                                setShowProduct(true)
                                                            }
                                                        },
                                                        toolbar: {
                                                            show: true
                                                        }
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 1,
                                                            horizontal: false
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    xaxis: {
                                                        categories: mostLoyal.map(i => {
                                                            return i.name
                                                        })
                                                    },
                                                    yaxis: {
                                                        show: false
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                        formatter: function (val) {
                                                            return global.IDR.format(val);
                                                        },
                                                    },
                                                    tooltip: {
                                                        y: {
                                                            formatter: function (val) {
                                                                return global.IDR.format(val);
                                                            },
                                                        },
                                                    },
                                                }
                                            }
                                            series={[
                                                {
                                                    data: mostLoyal.map(order => parseInt(order.total))
                                                }
                                            ]}
                                            height={300}
                                        />
                                        : <Loader active />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Dashboard;