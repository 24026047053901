import React, {useState, useEffect} from "react";
import { Form, Select, Button, Input, Divider, Label } from "semantic-ui-react";
import {Container, Row, Col, Stack, Alert} from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const ProductAttrbute = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [productColor, setProductColor] = useState([])
    const [selectedColor, setSelectedColor] = useState([])
    const [weight, setWeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [length, setLength] = useState(0)

    console.log(selectedColor)
    const handleSubmit = () => {
        const data = {
            color : selectedColor,
            weight : weight,
            width : width,
            height : height,
            length : length,
            id_product : location.state.selected.id_product
        }
        const endpoint = "https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=8"

        axios.post(endpoint, data)
        .then(res => { console.log(res.data)
            if (res.data.state === 200){ 
                navigate('/product')
            }
        })
    }

    
    useEffect(() => {
        const getColor = async () => {
            const endpoint = "https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=7" 
            
            await axios.get(endpoint)
            .then(res => { console.log(res.data.data)
                if (res.data.state === 200){ 
                    let data = res.data.data
                    let ar = []
    
                    data.map(item => {
                        let colorGroup = <Stack direction="horizontal" gap={0} style={{display:'inline'}}>
                            <span className="me-4">
                            <canvas className="border-start border-dark border-top border-bottom" style={{backgroundColor:item.color_main}} height="15%" width="15%" />
                            <canvas className="border-dark border-top border-bottom" style={{backgroundColor:item.color_secondary}} height="15%" width="15%" />
                            <canvas className="border-end border-dark border-top border-bottom" style={{backgroundColor:item.color_accent}} height="15%" width="15%" />
                            </span>
                            <span>{item.name}</span>
                        </Stack>
                        
                        ar.push(
                            {
                                key : item.id_color,
                                value : item.id_color,
                                text :  colorGroup,
                                name : item.name
                            }
                        )
                    })
                    setProductColor(ar)
                }
            })
        }
    

        getColor()
        setSelectedColor([...selectedColor, '169'])
    },[])


    return (
        <Container fluid>
            <Row className="mt-1">
                <div className="text-facebook h3 mb-0">Add Product Attribute</div>
                <small>
                    <span className="text-muted fw-bold">{location.state.selected.id_product} - </span>
                    <span className="fw-bold">{location.state.selected.product_name}</span>
                </small>
            </Row>
            <Divider/>

            <Row className="mb-3 mt-5 d-flex justify-content-center">
                <Col xl={6} lg={12} md={12} sm={12}>
                   
                    <Form widths="equal" onSubmit={() => handleSubmit()}>
                        <Form.Dropdown
                            search
                            control={Select}
                            options={productColor}
                            label={{children:"Color"}}
                            multiple
                            value={selectedColor}
                            onChange={(e,{value}) => setSelectedColor(value)}
                        />
                        <Form.Field>
                            <label>Weight</label>
                            <Input 
                                value={weight} 
                                label={{basic:true, content:'kg'}} 
                                labelPosition="right"
                                type="number" 
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Width</label>
                            <Input 
                                value={width} 
                                label={{basic:true, content:'cm'}} 
                                labelPosition="right"
                                type="number" 
                                onChange={(e) => setWidth(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Height</label>
                            <Input 
                                value={height} 
                                label={{basic:true, content:'cm'}} 
                                labelPosition="right"
                                type="number" 
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Length</label>
                            <Input 
                                value={length} 
                                label={{basic:true, content:'cm'}} 
                                labelPosition="right"
                                type="number" 
                                onChange={(e) => setLength(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Button content="Save Attribut" className="mt-5" color="facebook" type="submit"/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductAttrbute


