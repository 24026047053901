import React  from "react";
import axios from "axios";
import { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Divider, Form } from "semantic-ui-react";
import { useState } from "react";

export const Config = () => {
    const [conf, setConf] = useState([])
    const [value, setValue] = useState([])

    const handleUpdate = (type, name, value, id) => {
        let param = {
            type : type,
            name : name,
            value : value,
            id : id,
        }
        axios({
            method: "POST",
            url: `${global.SRV}/api_rudrunner_cms/controler/config.php/?index=2`,
            headers: { 'Content-Type': 'text/plain' },   
            data : param 
        })
        .then (res => { console.log(res.data)
            if (res.data.state === 200){
                alert("Config updated successfuly...")
            }else if (res.data.state === 500){
                alert(res.data.error)
            }else{
                alert('Update failed...!')
            }
        })
    }

    const handleChange = (val, id) => {
        const newValue = value.map(item => {
            if (item.id == id){
                return {...item, currentVal:val}
            }

            return item
        })
        setValue(newValue)
    }

    useEffect(() => {
        axios({
            method: "GET",
            url: `${global.SRV}/api_rudrunner_cms/controler/config.php/?index=1`,
            headers: { 'Content-Type': 'text/plain' },    
        })
        .then (res => {     console.log(res.data.data)
            if (res.data.state === 200){
                let arr = []
                setConf(res.data.data)
                res.data.data.map(item => {
                    arr.push({
                        id:item.id,
                        currentVal:item.nilai
                    })
                })
                setValue(arr)
            }
        })
    },[])

    console.log(value)
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Configuration</div>
                <div className="text-muted">All configuration can setup here</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={6} md={8} sm={12}>
                    <Row>
                        {
                            conf.map((item, i) => (
                                <Col md={12} className="mb-3" key={i}> 
                                    <Form onSubmit={() => handleUpdate(item.type, item.name, value.find(v => v.id == item.id).currentVal, item.id)} >
                                        <Form.Field>
                                        <Form.Input 
                                            label={<><div className="fw-bold mb-1">{item.name}</div><div className="mb-2" style={{lineHeight:1.2, fontSize:'0.8em'}}>{item.description}</div></>} 
                                            type={item.type} 
                                            defaultValue={item.nilai}
                                            value={value.filter(v => v.id == item.id).currentVal} 
                                            size="small"
                                            onChange={(e) => handleChange(e.target.value, item.id)}
                                        />
                                        <Form.Button 
                                            content="Update" 
                                            type="submit" 
                                            className="text-end" 
                                            color="facebook" 
                                        />
                                        </Form.Field>
                                    </Form>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}