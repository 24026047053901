import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col, Stack, Image, Modal } from "react-bootstrap";
import { Divider, Icon, Label, Checkbox, Button, Popup, Loader, Dimmer } from "semantic-ui-react";
import axios from "axios";
import { useMemo } from "react";
import Table from "../component/Table";
import { Fragment } from "react";
import { Toast } from "../component/Toast";

const Customer = () => {
    const [customer, setCustomer] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [customerProd, setCustomerProd] = useState([])
    const [selCustomer, setSelCustomer] = useState({})
    const [loaderActive, setLoaderActive] = useState(false)
    const [orderTotal, setOrderTotal] = useState(0)
    const [totalQty, setTotalQty] = useState(0)

    const handleClose = () => (
        setModalShow(false)
    )

    const handleShow = () => (
        setModalShow(true)
    )

    const handleClickCustomer = (data) => {
        setSelCustomer({
            name: <span className="text-capitalize">{data.first_name + ' ' + data.last_name}</span>,
            isMember: data.isMember == 'Y' ? 'Member' : 'Customer',
        })
        getOrderProduct(data.id_customer)
        handleShow()
    }

    const handleActive = (id) => {
        const param = { id_customer: id }
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/customer.php/?index=2`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    getCustomer()
                }
            })
    }

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_customer"
        },
        {
            Header: "First Name",
            accessor: "first_name",
            Cell: ({ cell }) => {
                console.log(cell.row.original)
                if (cell.row.original.avatar !== '') {
                    return (
                        <Fragment>
                            <Stack direction="horizontal" gap={2}>
                                <Image src={cell.row.original.avatar} width={36} height={36} roundedCircle />
                                <Stack direction="vertical" gap={0}>
                                    <div className="text-capitalize">{cell.value + ' ' + cell.row.original.last_name}</div>
                                    <div className="text-muted" style={{ fontSize: '0.9em' }}>{cell.row.original.email}</div>
                                </Stack>
                            </Stack>
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment>
                            <Stack direction="horizontal" gap={2}>
                                <Image src={`https://ui-avatars.com/api/?name=${cell.row.original.first_name}+${cell.row.original.last_name}`} width={36} height={36} roundedCircle />
                                <Stack direction="vertical" gap={0}>
                                    <div className="text-capitalize">{cell.value + ' ' + cell.row.original.last_name}</div>
                                    <div className="text-muted" style={{ fontSize: '0.9em' }}>{cell.row.original.email}</div>
                                </Stack>
                            </Stack>
                        </Fragment>
                    )
                }
            }

        },
        {
            Header: "Gender",
            accessor: "gender",
            Cell: ({ cell }) => (cell.value === 'M' ? <Icon name="man" color="blue" size="large" /> : <Icon name="woman" color="pink" size="large" />)
        },
        {
            Header: "Last Login",
            accessor: "last_log"
        },
        {
            Header: "Verified",
            accessor: "verified",
            Cell: ({ cell }) => (cell.value == 1 ? <Label content="Yes" size="small" /> : <Label content="No" size="small" />)
        },
        {
            Header: "Verified Date",
            accessor: "verified_date"
        },
        {
            Header: "Active",
            accessor: "isActive",
            Cell: ({ cell }) => (
                <Checkbox
                    toggle
                    checked={cell.value === 'Y' ? true : false}
                    style={{ transform: 'scale(.7)' }}
                    onChange={() => handleActive(cell.row.values.id_customer)}
                />
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini">
                    <Popup trigger={
                        <Button icon="search" basic onClick={() => handleClickCustomer(cell.row.original)} />
                    }
                        size="mini"
                        content="Products that have been ordered"
                    />
                </Button.Group>
            )
        }
    ], [])

    const getCustomer = async () => {
        await axios.get(`${global.SRV}api_rudrunner_cms/controler/customer.php/?index=1`)
            .then(res => {
                if (res.data.state === 200) {
                    setCustomer(res.data.data)
                }
            })
    }

    const getOrderProduct = async (idCustomer) => {
        setLoaderActive(true)
        await axios.get(`${global.SRV}api_rudrunner_cms/controler/order.php/?index=9&id_customer=${idCustomer}`)
            .then(res => {
                if (res.data.state === 200) {
                    setCustomerProd(res.data.data)
                    setOrderTotal(res.data.order_total)
                    setTotalQty(res.data.total_qty)
                    setLoaderActive(false)
                } else {
                    setLoaderActive(false)
                    Toast.fire({
                        icon: 'error',
                        text: "Error on load data",
                        position: "center",
                    })
                }
            })
    }

    useEffect(() => {
        getCustomer()
    }, [])

    return (
        <Container fluid>
            <Modal show={modalShow} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Products</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dimmer active={loaderActive} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <div className="mb-3">
                        <span className="me-2">{selCustomer.name}</span>
                        <span><Label content={selCustomer.isMember} size="mini" color="orange" /></span></div>
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <td>#</td>
                                <td>Order</td>
                                <td>Product</td>
                                <td>Price</td>
                                <td>Shipping</td>
                                <td>Total</td>
                                <td>Size</td>
                                <td>Qty</td>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.8em'}}>
                            {
                                customerProd.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            <Stack direction="vertical" gap={0}>
                                                <div className="fw-bold">{item.id_order}</div>
                                                <div>{item.date}</div>
                                            </Stack>
                                        </td>
                                        <td>
                                            <Stack direction="horizontal" gap={2}>
                                                <Image className="border" rounded src={item.image} width={45} height={40} />
                                                <Stack direction="vertical" gap={0} style={{ width: 150 }}>
                                                    <Popup trigger={<div className="text-truncate fw-bold">{item.item_name}</div>} content={item.item_name} size="mini" />
                                                    <div>{item.id_product}</div>
                                                </Stack>
                                            </Stack>
                                        </td>
                                        <td>{global.IDR.format(item.total_price)}</td>
                                        <td>{global.IDR.format(item.courier_cost)}</td>
                                        <td>{global.IDR.format(item.total_payment)}</td>
                                        <td>{item.size}</td>
                                        <td>{item.qty}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr style={{fontSize:'0.9em'}}>
                                <td colSpan={6} className="text-end">Total Spend : <span className="fw-bold">{global.IDR.format(orderTotal)}</span></td>
                                <td colSpan={2} className="text-end">Total Qty : <span className="fw-bold">{totalQty}</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </Modal.Body>
            </Modal>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Customer</div>
                <div className="text-muted">This is the registered customer</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Table columns={columns} data={customer} />
                </Col>
            </Row>
        </Container>
    )
}

export default Customer