import React from "react";
import {Container, Row, Col} from "react-bootstrap"
import { Divider, Label } from "semantic-ui-react";
import Table from "../component/Table";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useMemo } from "react";

const CustomerAddr = () => {
    const [address, setAddress] = useState([])
    
    const getAddr = async () => {
        await axios({
            method:"GET",
            url : `${global.SRV}api_rudrunner_cms/controler/customer.php/?index=3`
        })
        .then (res=> {
            console.log(res.data)
            if (res.data.state === 200){
                setAddress(res.data.data)
            }
        })
    }

    const columns = useMemo(() => [
        {
            Header:"ID",
            accessor:"id_address"
        },
        {
            Header:"Customer",
            accessor:"customer"
        },
        {
            Header:"Address Label",
            accessor:"address_label"
        },
        {
            Header:"Address",
            accessor:"address_detail"
        },
        {
            Header:"Sub District",
            accessor:"sub_district"
        },
        {
            Header:"Disctrict",
            accessor:"district"
        },
        {
            Header:"City",
            accessor:"city"
        },
        {
            Header:"Province",
            accessor:"province"
        },
        {
            Header:"Default",
            accessor:"isDefault",
            Cell : ({cell}) => (cell.value === '1' ? <Label content="Yes" size="tiny" color="blue"/> : <Label content="No" size="tiny"/>)
        },
        
    ],[])

    useEffect(() => {
        getAddr()
    },[])

    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Address</div>
                <div className="text-muted">This is customer address list that has been registered by customer</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Table columns={columns} data={address} />
                </Col>
            </Row>
        </Container>
    )
}

export default CustomerAddr