import React from "react";
import { useTable, usePagination, useExpanded, useRowSelect, useGlobalFilter, useSortBy, } from "react-table";
import { Table as Tbl, Row, Col, Image } from "react-bootstrap";
import { Button, Dropdown, Input, Icon, Dimmer, Loader } from "semantic-ui-react";
import { useState } from "react";
import { useEffect } from "react";
import emptyImg from "../../assets/no_data.png"


const Table = ({ columns: userColumns, data, renderRowSubComponent, checkbox, pageRow, hideColumn }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        selectedFlatRows,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    } = useTable(
        {
            columns: userColumns,
            data,
            autoResetPage: false,
            autoResetFilters: false,
            initialState: { pageIndex: 0, pageSize: pageRow ? pageRow : 10, hiddenColumns: hideColumn ? hideColumn : '' },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if (checkbox) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
    )

    const [loading, setLoading] = useState(false)
    const [stopLoad, setStopLoad] = useState(false)
    const tableStyle = {
        width: 'auto',
        minWidth: 0,
        maxWidth: '200px',
        display: 'inlineBlock',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }


    const opt = [
        { key: '5', value: 5, text: '5 records' },
        { key: '10', value: 10, text: '10 records' },
        { key: '20', value: 20, text: '20 records' },
        { key: '50', value: 50, text: '50 records' },
        { key: '100', value: 100, text: '100 records' },
    ]

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )

    const handleChangePage = (e, { value }) => {
        setPageSize(value)
    }

    useEffect(() => {
        if (data.length == 0) {
            setLoading(false)
        } else if (data.length > 0) {
            setLoading(true)
        }
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            setStopLoad(true)
        }, 50000)
    }, [loading])


    return (
        <>
            <Row className="mb-3">
                <Col xl={12} slg={12} md={12} xs={12} className="d-flex justify-content-end mb-2">
                    <Input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} icon="search" size="small" />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Tbl hover responsive {...getTableProps()}>
                        <thead className="fw-bold bg-light text-facebook" style={{ whiteSpace: 'nowrap' }}>
                            {headerGroups.map(headerGroups => (
                                <tr {...headerGroups.getHeaderGroupProps()}>
                                    {headerGroups.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            onClick={() => {
                                                column.canSort && column.toggleSortBy(!column.isSortedDesc)
                                            }
                                            }
                                        >
                                            {column.render("Header")}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </th>
                                        // <td {...column.getHeaderProps()}>{column.render('Header')}</td>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} style={tableStyle}>
                            {
                                !stopLoad ?
                                    <tr hidden={loading}><td colSpan={userColumns.length} className="text-center"><Icon loading name='spinner' />Loading...</td></tr>
                                    :
                                    <tr hidden={loading}><td colSpan={userColumns.length} className="text-center"><Image src={emptyImg} width={120} height={120} /></td></tr>
                            }
                            {page.map((row, i) => {
                                prepareRow(row)
                                const rowProps = row.getRowProps()
                                return (
                                    // Use a React.Fragment here so the table markup is still valid
                                    <React.Fragment key={rowProps.key} >
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                <td colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            })}

                        </tbody>
                    </Tbl>
                </Col>
                <Col lg={12}>
                    <Row className="mt-3">
                        <Col lg={9} md={12} sm={12} xs={12} className="mb-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            | Show &nbsp;
                            <Dropdown
                                className="fw-bold"
                                options={opt}
                                onChange={handleChangePage}
                                defaultValue={pageSize}
                            /> &nbsp;
                            <span>
                                | Go to page:{' '}
                                <Input
                                    size="small"
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </Col>
                        <Col lg={3} md={12} sm={12} xs={12} className="d-flex justify-content-end mb-2">
                            <Button.Group floated="left" size="mini" basic>
                                <Button icon="angle double left" onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                                <Button icon="angle left" onClick={() => previousPage()} disabled={!canPreviousPage} />
                                <Button icon="angle right" onClick={() => nextPage()} disabled={!canNextPage} />
                                <Button icon="angle double right" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                            </Button.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Table;