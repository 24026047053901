import React, {useState, useEffect} from "react";
import { Divider, Form } from "semantic-ui-react";
import { Container, Col, Row } from "react-bootstrap";
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import moment from "moment/moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FlashsaleAdd = () => {
    const navigate = useNavigate()
    const [active, setActive] = useState(false)
    const dateNow = new Date();
    const [dataForm, setDataForm] = useState({
        name : '',
        description : '',
        discount: 0,
        active_date : [
            new Date(),
            new Date(dateNow.setDate(dateNow.getDate() + 7))
        ],
        active : 'N',
    })

    const handleSubmit = (data) => {
        axios ({
            method:"POST",
            url:`${global.SRV}api_rudrunner_cms/controler/event.php/?index=1`,
            headers:{'Content-Type': 'text/plain'},
            data:data
        }).
        then(res => {
            if (res.data.state === 200){
                alert(res.data.message)
                navigate('/flashsale')
            }
        })
    }

    const handleCheckbox = (checked) => { 
        if (checked){
            setDataForm({...dataForm, active:'Y'})
        }else{
            setDataForm({...dataForm, active:'N'})
        }
    }
   
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Add Flashsale</div>
                <div className="text-muted">Here you can create flashsale event more than one that will show on homepage</div>
            </Row>
            <Divider />

            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Form widths="equal" onSubmit={() => handleSubmit(dataForm)}>
                        <Form.Input label="Flashsale Name" defaultValue={dataForm.name} onChange={(e) => setDataForm({...dataForm, name:e.target.value})} required/>
                        <Form.Input label="Description" defaultValue={dataForm.description} onChange={(e) => setDataForm({...dataForm, description:e.target.value})}/>
                        {/* <Form.Input label="Discount Percent" type="number" defaultValue={dataForm.discount} onChange={(e) => setDataForm({...dataForm, discount:e.target.value})} required/> */}
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker 
                                defaultValue={dataForm.active_date}
                                format="yyyy-MM-dd HH:mm:ss" 
                                appearance="default" 
                                size="lg" 
                                style={{width:"100%"}} 
                                placement="bottomStart"
                                onOk={(e) => setDataForm({...dataForm, active_date:e})}
                                required
                            />
                        </Form.Field>
                        <Form.Checkbox inline label= "Active" defaultChecked={dataForm.active === 'Y' ? true : false} onChange={(e, data) => handleCheckbox(data)}/>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Save Flashsale" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default FlashsaleAdd