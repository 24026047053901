import axios from "axios";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Button, Form, Popup, Dimmer, Loader } from "semantic-ui-react";
import Table from "../component/Table";

const FlashsaleDetail = () => {
    const location = useLocation().state
    const [fsDetail, setFsDetail] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [productWeb, setProductWeb] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [discount, setDiscount] = useState(0)
    const [loader, setLoader] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "ID Product",
            accessor: "id_product"
        },
        {
            Header: "Product",
            accessor: "product_name"
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Base Price",
            accessor: "price",
            Cell: (cell) => ('Rp ' + cell.value)
        },
        {
            Header: "Discount",
            accessor: "fs_discount"
        },
        {
            Header: "Action",
            Cell: (cell) => (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="Remove" trigger={<Button icon="eraser" size="mini" onClick={() => removeProduct(cell.row.values.id_product, location.id_fs)} />} />
                    </Button.Group>
                </Fragment>
            )
        }
    ], [])

    const removeProduct = (idProd, idFs) => {
        let param = {
            id_product: idProd,
            id_fs: idFs
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=3`,
            headers: {
                'Content-Type': 'text/plain'
            },
            data: param
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200) {
                    getFsDetail(location.id_fs)
                }
            })
    }

    const getFsDetail = async (idFs) => {
        const param = {
            id_fs: idFs
        }
        await axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=2`,
            headers: {
                'Content-Type': 'text/plain'
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setFsDetail(res.data.data)
                }
            })
    }

    const showModalAdd = () => {
        setModalAdd(true)
        getProductWeb()
    }

    const getProductWeb = async () => {
        setLoader(true)
        await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php?index=2")
            .then(res => {
                if (res.data.state === 200) {
                    setProductWeb(() => {
                        let opt = []

                        res.data.data.map(i => {
                            opt.push({
                                key: i.id_product,
                                value: i.id_product,
                                text: i.id_product,
                            })
                        })

                        return opt.filter(o => !fsDetail.some(x => o.value == x.id_product))
                    }
                    )
                    setLoader(false)
                }
            })
    }

    const handleSubmit = () => {
        const param = {
            id_product: selectedProduct,
            discount: discount,
            id_fs: location.id_fs,
            date_start: location.date_start,
            date_end: location.date_end,
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=4`,
            headers: {
                'Content-Type': 'text/plain'
            },
            data: param
        })
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200) {
                    getFsDetail(location.id_fs)
                    setModalAdd(false)
                }
            })
    }

    useEffect(() => {
        getFsDetail(location.id_fs)
    }, [location])

    return (
        <Container fluid>
            <Modal show={modalAdd} onHide={() => setModalAdd(false)} autoFocus backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Adding Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Dimmer active={loader} inverted>
                                <Loader inverted content='Antosan sakedap..' size="tiny"/>
                            </Dimmer>
                            <Form widths="equal" onSubmit={handleSubmit}>
                                <Form.Select
                                    label="Select Article"
                                    search
                                    options={productWeb}
                                    onChange={(e, { value }) => setSelectedProduct(value)}
                                    required
                                />
                                <Form.Input label="Discount (%)" value={discount} type="number" onChange={(e) => setDiscount(e.target.value)} required />
                                <div className="text-end">
                                    <Button content="Add" color="facebook" icon="add" />
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Flashsale Products</div>
                <div className="text-muted">List of Flashsale Products</div>
            </Row>
            <Divider />

            <Row className="mb-3">
                <Col md={6} xs={12} className="d-flex justify-content-start">
                    <Stack direction="vertical" gap={0}>
                        <div className=" fs-5">{location.name}</div>
                        <div className="text-muted">{location.description}</div>
                    </Stack>
                </Col>
                <Col md={6} xs={12} className="d-flex justify-content-end">
                    <Button content="New Product" color="facebook" size="small" onClick={showModalAdd} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col md={12} xl={12} sm={12}>
                    <Table columns={columns} data={fsDetail} />
                </Col>
            </Row>
        </Container>
    )
}

export default FlashsaleDetail