import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { Button, Divider, Popup } from "semantic-ui-react";
import axios from 'axios'
import { useFetcher, useNavigate } from "react-router-dom";
import Table from "../component/Table";
import Swal from 'sweetalert2'
import Pesan from "../component/Pesan";


const Color = () => {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [showAlert, setShowAlert] = useState(false)

    const getColor = async () => {
        await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=7")
            .then(res => {
                if (res.data.state === 200) {
                    setData(res.data.data)
                }
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3b5998',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=29", { id_color: id })
                    .then(res => {
                        getColor()
                        setShowAlert(true)
                    })
            }
        })
    }

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_color"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Primary",
            accessor: "color_main",
            Cell: ({ cell }) => (
                <canvas width={20} height={20} className="border border-dark" style={{ backgroundColor: cell.value }} />
            )
        },
        {
            Header: "Secondary",
            accessor: "color_secondary",
            Cell: ({ cell }) => (
                <canvas width={20} height={20} className="border border-dark" style={{ backgroundColor: cell.value }} />
            )
        },
        {
            Header: "Accent",
            accessor: "color_accent",
            Cell: ({ cell }) => (
                <canvas width={20} height={20} className="border border-dark" style={{ backgroundColor: cell.value }} />
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="Edit" trigger={<Button icon="edit" onClick={() => navigate('/color_edit', { state: cell.row.values })} size="mini" />} />
                        <Popup content="Delete" trigger={<Button icon="eraser" onClick={() => handleDelete(cell.row.values.id_color)} />} size="mini" />
                    </Button.Group>
                </Fragment>
            )
        },
    ], [])

    useEffect(() => {
        getColor()
    }, [])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Product Color</div>
                <div className="text-muted">This is the available color list for product</div>
            </Row>
            <Divider />

            <Row className="d-flex justify-content-center mb-3">
                <Col xl={10} lg={12} md={12} sm={12}>
                    <Pesan variant="success" content="Success delete selected item" header="Item deleted" setShowAlert={setShowAlert} show={showAlert} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xl={10} lg={12} md={12} sm={12} className="d-flex justify-content-start mb-3">
                    <Button content="New Color" color="facebook" onClick={() => navigate('/color_add')} />
                </Col>
                <Col xl={10} lg={12} md={12} sm={12}>
                    {
                        data &&
                        <Table columns={columns} data={data} />
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default Color