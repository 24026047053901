import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Modal, Row, Tab, Table } from "react-bootstrap";
import { Button, Divider, Form } from "semantic-ui-react";

const SizeGroup = () => {
    const [groupData, setGroupData] = useState([])
    const [groupName, setGroupName] = useState("")
    const [groupDesc, setGroupDesc] = useState("")
    const [groupNameEdit, setGroupNameEdit] = useState("")
    const [groupDescEdit, setGroupDescEdit] = useState("")
    const [modalShow, setModalShow] = useState(false)
    const [groupId, setGroupId] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)

    const handleSubmit = () => {
        setLoadingAdd(true)
        let data = {
            size_group: groupName,
            desc: groupDesc
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=3`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        }).
            then(res => {
                if (res.data.state === 200) {
                    getSizeGroup()
                    setGroupName('')
                    setGroupDesc('')
                    setLoadingAdd(false)
                }
            })
    }

    const getSizeGroup = async () => {
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=4`,
            headers: { 'Content-Type': 'application/json' },
        }).
            then(res => {
                if (res.data.state === 200) {
                    setGroupData(res.data.data)
                }
            })
    }

    const deleteSizeGroup = (id) => {
        let data = {
            id: id
        }

        if (window.confirm("Are you sure want to delete?")) {
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=5`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).
                then(res => {
                    if (res.data.state === 200) {
                        getSizeGroup()
                    } else if (res.data.state === 500) {
                        alert(res.data.message)
                    }
                })
        }
    }

    const editSizeGroup = () => {
        setLoading(true)
        let data = {
            id: groupId,
            group_name: groupNameEdit,
            description: groupDescEdit,
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=6`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        }).
            then(res => {
                if (res.data.state === 200) {
                    setLoading(false)
                    getSizeGroup()
                    setModalShow(false)
                } else if (res.data.state === 500) {
                    alert(res.data.message)
                }
            })
    }

    const handleClickEdit = (data) => {
        setGroupId(data.id)
        setGroupNameEdit(data.group_name)
        setGroupDescEdit(data.description)
        setModalShow(true)
    }

    useEffect(() => {
        getSizeGroup()
    }, [])

    return (
        <Container fluid>
            <Row>
                <div className="text-facebook h3 mb-0">Size Group</div>
                <span className="text-muted">Add size grup such as (US,UK,EU)</span>
            </Row>
            <Divider />
            <Modal show={modalShow} size="md" onHide={() => setModalShow(false)} autoFocus backdrop="static" keyboard={false}>
                <Modal.Header closeButton><Modal.Title>Edit Size Group</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={editSizeGroup}>
                        <Form.Input value={groupId} style={{ display: 'none' }} />
                        <Form.Input label="Group Name" value={groupNameEdit} required={true} onChange={(e) => setGroupNameEdit(e.target.value)} />
                        <Form.Input label="Description" value={groupDescEdit} onChange={(e) => setGroupDescEdit(e.target.value)} />
                        <Button content="Update" loading={loading} disabled={loading} type="submit" color="facebook" />
                    </Form>
                </Modal.Body>
            </Modal>
            <Row className="d-flex justify-content-center mt-5 mb-5">
                <Col xl={6} lg={8} md={12} sm={12} className="mb-3">
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Input label="Group Name" value={groupName} required={true} onChange={(e) => setGroupName(e.target.value)} />
                        <Form.Input label="Description" value={groupDesc} onChange={(e) => setGroupDesc(e.target.value)} />
                        <Button content="Submit" loading={loadingAdd} disabled={loadingAdd} type="submit" color="facebook" />
                    </Form>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-5 mb-5">
                <Col xl={8} lg={8} md={12} sm={12}>
                    <Table>
                        <thead className="table-light">
                            <tr>
                                <td>#</td>
                                <td>Group Name</td>
                                <td>Description</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                groupData.map((row, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{row.group_name}</td>
                                        <td>{row.description}</td>
                                        <td>
                                            <Button.Group size="mini" basic>
                                                <Button icon="trash" onClick={() => deleteSizeGroup(row.id)} />
                                                <Button
                                                    icon="edit outline"
                                                    onClick={() =>
                                                        handleClickEdit(row)
                                                    }
                                                />
                                            </Button.Group>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default SizeGroup;