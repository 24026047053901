import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form, Select, Button, LabelGroup, Divider, Segment } from "semantic-ui-react";


const ProductAdd = () => {
    const navigate = useNavigate()
    const [genderList, setGenderList] = useState([])
    const [segmentList, setSegmentList] = useState([])
    const [products, setProducts] = useState([])
    const [productQty, setProductQty] = useState(0)
    const [productName, setProductName] = useState('')
    const [productDesc, setProductDesc] = useState(null)
    const [idGender, setIdGender] = useState(null)
    const [idBrand, setIdBrand] = useState(null)
    const [idSegment, setIdSegment] = useState(null)
    const [idProduct, setIdProduct] = useState(null)
    const [category, setCategory] = useState([])
    const [selectedCat, setSelectedCat] = useState(null)
    const [sizeGroup, setSizeGroup] = useState([])
    const [selectedSizeGroup, setSelectedSizeGroup] = useState('')
    const [loading, setLoading] = useState(false)
    
    const handleChangeProduct = (e, { value }) => {
        let qty = products.filter((i) => i.value === value)[0]['qty']
        let name = products.filter((i) => i.value === value)[0]['name']
        let idBrand = products.filter((i) => i.value === value)[0]['id_brand']

        setProductQty(qty)
        setProductName(name)
        setIdBrand(idBrand)
        setIdProduct(value)
    }


    const handleChangeCat = (e, {value}) => {
        let valueJson = value.map(i => JSON.parse(i)) // PARSING BALIK KE JSON
        setSelectedCat(valueJson)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (idProduct && productName && idGender && idSegment && selectedCat){
            addProduct()
        } else {
            alert('Please complete it first before submit..! \n tolong cek lagi barangkali ada yang belum diisi ya...')
        }
    }

    const addProduct = () => {
        setLoading(true)
        const endpoint = "https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=6"

        axios({
            method: "POST",
            url : endpoint,
            headers : {
                'Content-Type': 'application/json',
            },
            data : {
                id_product : idProduct,
                product_name : productName,
                product_desc : productDesc,
                initial_qty : productQty,
                id_gender : idGender,
                id_segment : idSegment,
                category : selectedCat,
                id_brand : idBrand,
                size_group : selectedSizeGroup,
            }
        })
        .then(res => {
            if (res.data.state === 200){
                alert(res.data.message)
                setLoading(false)
                navigate("/product")
            } else {
                alert("Error")
            }
        })
    }

    const handleClickSizeGroup = (e, {value}) => {
        setSelectedSizeGroup(value)
    }

    useEffect(() => {
        // POPULATE LIST GENDER
        const getGenderList = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=3")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []
                        data.map(item => (
                            opt.push(
                                {
                                    key: item.id_gender,
                                    value: item.id_gender,
                                    text: item.gender
                                }
                            )
                        ))
                        setGenderList(opt)
                    }
                })
        }
        // POPULATE LIST SEGMENT
        const getSegmentList = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=4")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []
                        data.map(item => (
                            opt.push(
                                {
                                    key: item.id_segment,
                                    value: item.id_segment,
                                    text: item.name
                                }
                            )
                        ))
                        setSegmentList(opt)
                    }
                })
        }
    
        const getProduct = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=1")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []

                        data.map((item) => {
                            opt.push(
                                {
                                    key: item.article,
                                    value: item.article,
                                text: item.article + ' | ' + item.description,
                                    name: item.description,
                                    qty: item.total_qty,
                                    id_brand: item.brand_code,
                                }
                            )
                        })
                        setProducts(opt)
                    }
                })
        }

        const getCategory =  async () => {
            await axios.get('https://hoopsdevteam.com/api_web/controler/category_rr.php/?index=3')
            .then(res => {
                if (res.data.length > 0) {
                    setCategory(res.data.map((item,i) => (
                        {
                            key:i,
                            text:(item.child_name) ? item.parent_name + ' > ' + item.child_name : item.parent_name,
                            value: JSON.stringify({id_parent:item.id_parent, id_child:item.id_child}), // UBAH JADI STRING SOALNYA GA BISA OBJECT
                        }
                    )))
                }
            })
        } 

        const getSizeGroup = async () => {
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/product.php/?index=4`,
                headers: { 'Content-Type': 'application/json' },
            }).
                then(res => {
                    if (res.data.state === 200) {
                        setSizeGroup(res.data.data.map((item,i) => (
                            {
                                key:i,
                                text:item.group_name,
                                value: item.group_name,
                            }
                        )))
                    }
                })
        }

        getGenderList()
        getSegmentList()
        getProduct()
        getCategory()
        getSizeGroup()
    }, [])
   
    return (
        <Container fluid>
             <Row>
                <div className="text-facebook h3 mb-0">Adding Product</div>
                <span className="text-muted">Add existing products in the system to the website, so we can customize (name, description, etc.)</span>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mt-5 mb-5">
                <Col xl={6} lg={12} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Select
                            required
                            search
                            control={Select}
                            options={products}
                            label={{ children: 'Products' }}
                            onChange={handleChangeProduct}
                        />
                        <Form.Input label="Product Name" value={productName} onChange={(e, { value }) => setProductName(value)} required/>
                        <Form.TextArea label='Product Description' onChange={(e, {value}) => setProductDesc(value)} maxLength={1000}/>
                        <Form.Input label="Initial Quantity" value={productQty} readOnly />
                        <Form.Select control={Select} options={genderList} label={{ children: 'Gender' }} onChange={(e, {value}) => setIdGender(value)} required/>
                        <Form.Select control={Select} options={segmentList} label={{ children: 'Segment' }} onChange={(e, {value}) => setIdSegment(value)} required/>
                        <Form.Dropdown 
                            required
                            search
                            control={Select} 
                            options={category} 
                            label={{children: 'Category'}} 
                            onChange={handleChangeCat}
                            multiple
                        />
                        <Form.Dropdown
                            required
                            search
                            control={Select}
                            options={sizeGroup}
                            label={{children: 'Size Group'}} 
                            onChange={handleClickSizeGroup}
                        />
                        <Form.Button loading={loading} disabled={loading} content="Submit" type="submit" color="facebook"/>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ProductAdd;