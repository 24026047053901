import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Container, Row, Col, Image} from "react-bootstrap";
import { Divider, Form, Checkbox, Label, Button, Dropdown, Icon, Message } from "semantic-ui-react";
import Table from "../component/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pesan from "../component/Pesan";

const Category = () => {
    const navigate = useNavigate()
    const [category, setCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState()
    const [display, setDisplay] = useState(null)
    const [showAlert, setShowAlert] = useState(false)

    const getCategory = async () => {
        await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=9")
            .then(res => {
                if (res.data.state === 200) {
                    setCategory(res.data.data)
                }
            })
    }

    const handleClickSubCategory = (row) => {
        navigate("/category_sub", { state: row })
        console.log(row)
    }

    const handleClickAddNew = () => {
        navigate("/category_add")
    }

    const data = useMemo(() => category, [category])

    const columns = useMemo(() => [
        // {
        //     id: "expander",
        //     Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        //         <span {...getToggleAllRowsExpandedProps()}>
        //             {isAllRowsExpanded ? <Icon name="angle down" /> : <Icon name="angle right" />}
        //         </span>
        //     ),
        //     Cell: ({ row }) =>
        //         row.canExpand ? (
        //             <span
        //                 {...row.getToggleRowExpandedProps({
        //                     style: {
        //                         paddingLeft: `${row.depth * 2}rem`
        //                     }
        //                 })}
        //             >
        //                 {row.isExpanded ? <Icon name="angle down" /> : <Icon name="angle right" />}
        //             </span>
        //         ) : null,
        //     SubCell: () => null
        // },
        {
            Header: "ID",
            accessor: (d) => d.id_category,
            SubCell: (cellProps) => (
                <>{cellProps.value}</>
            )
        },
        {
            Header: "Category Name",
            accessor: "category_name"
        },
        {
            Header: "Image",
            accessor: "header_image",
            Cell: ({ cell }) => (
                (cell.value !== "") ? <Image src={cell.value} width={35} height={25} rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={35} height={25} rounded />
            )
        },
        {
            Header: "Thumbnail",
            accessor: "thumbnail_image",
            Cell: ({ cell }) => (
                (cell.value !== "") ? <Image src={cell.value} width={35} height={25} rounded /> : <Image src="https://via.placeholder.com/500x400.png" width={35} height={25} rounded />
            )
        },

        {
            Header: "Nav.",
            accessor: "on_navigation",
            Cell: ({ cell }) => (
                (cell.value === '1') ? <Icon name="check" className="text-facebook" /> : <Icon name="close" className="text-muted" />
            )
        },
        {
            Header: "Page",
            accessor: "on_page",
            Cell: ({ cell }) => (
                (cell.value === '1') ? <Icon name="check" className="text-facebook" /> : <Icon name="close" className="text-muted" />
            )
        },
        {
            Header: "Position",
            accessor: "position"
        },
        {
            Header: "Displayed",
            accessor: "category_state",
            Cell: ({ cell }) => {
                return (
                    <Checkbox
                        toggle
                        defaultChecked={cell.value === '1' ? true : false}
                        style={{ transform: 'scale(.7)' }}
                        onChange={(e) => handleChangeDisplay(e, cell.row.values)}
                    />
                )
            }
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Fragment>
                    <Dropdown
                        floating
                        labeled
                        className='icon'
                        icon="ellipsis vertical"
                    >
                        <Dropdown.Menu className="left">
                            <Dropdown.Item icon="edit outline" text="Edit" onClick={() => navigate('/category_edit/', {state:cell.row.values})}/>
                            <Dropdown.Item icon="zoom in" text="Sub category" onClick={() => handleClickSubCategory(cell.row.values)} />
                        </Dropdown.Menu>
                    </Dropdown>
                </Fragment>
            )
        }
    ])

    const handleChangeDisplay = (e, value) => {
        updateState(value.ID)

    }

    const updateState = (id) => {
        let data = {
            id_category: id,
        }
        axios.post("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=14", data)
            .then(res => {
                if (res.data.state === 200) {
                    getCategory()
                    setShowAlert(true)
                }
            })
    }

    const renderRowSubComponent = React.useCallback(
        ({ row }) => {

            return (
                <pre
                    style={{
                        fontSize: '10px',
                    }}
                >
                    <code>{JSON.stringify({ values: row.subRows[0].original }, null, 2)}</code>
                </pre>
            )
        },
        []
    )

    useEffect(() => {
        getCategory()
    }, [])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Category</div>
                <div className="text-muted">List of categories that are used to group products on the website</div>
            </Row>
            <Divider />
            <Row>
                <Col lg={12}>
                    <Pesan variant="success" show={showAlert} setShowAlert={setShowAlert} content="update category state success" header="Success"/>
                </Col>
            </Row>
            <Row className="mb-3 pt-3">
                <Col md={12} className="d-flex justify-content-start">
                    <Button content="New Category" color="facebook" onClick={handleClickAddNew} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12} md={12} lg={12} xl={12}>
                    <Table columns={columns} data={data} renderRowSubComponent={renderRowSubComponent} />
                </Col>
            </Row>
        </Container>
    )
}

export default Category;