import React from "react";
import { Message } from "semantic-ui-react";

const Pesan = ({ variant, show, setShowAlert, content, header }) => {
    if (show) {
        if (setShowAlert) {
            return (
                <Message
                    positive
                    icon="alarm outline"
                    header={header}
                    variant={variant}
                    content={content}
                    onDismiss={() => setShowAlert(false)}
                    
                />
            )
        } else {
            return (
                <Message
                    positive
                    icon="alarm outline"
                    header={header}
                    content={content}
                />
            )
        }
    }
}

export default Pesan