
import './App.css';
import SideBarMenu from './view/component/SideBarMenu';
import { Route, Routes } from 'react-router-dom';
import Product from './view/page/Product';
import Dashboard from './view/page/Dashboard';
import Category from './view/page/Category';
import { useEffect, useState } from 'react';
import ProductAdd from './view/page/ProductAdd';
import ProductImage from './view/page/ProductImage';
import ProductAttrbute from './view/page/ProductAttribute';
import CategorySub from './view/page/CategorySub';
import CategoryAdd from './view/page/CategoryAdd';
import CategorySubAdd from './view/page/CategorySubAdd';
import ProductEdit from './view/page/ProductEdit';
import ProductAttributeEdit from './view/page/ProductAttributeEdit';
import CategoryEdit from './view/page/CategoryEdit';
import CategorySubEdit from './view/page/CategorySubEdit';
import Color from './view/page/Color';
import ColorAdd from './view/page/ColorAdd';
import ColorEdit from './view/page/ColorEdit';
import Customer from './view/page/Customer';
import CustomerAddr from './view/page/CustomerAddr';
import Order from './view/page/Order';
import OrderDetail from './view/page/OrderDetail';
import Voucher from './view/page/Voucher';
import VoucherAdd from './view/page/VoucherAdd';
import VoucherEdit from './view/page/VoucherEdit';
import VoucherSpecial from './view/page/VoucherSpecial';
import VoucherSpecialAdd from './view/page/VoucherSpecialAdd';
import VoucherSpecialEdit from './view/page/VoucherSpecialEdit';
import CarouselBanner from './view/page/CarouselBanner';
import CarouselBannerAdd from './view/page/CarouselBannerAdd';
import CarouselBannerEdit from './view/page/CarouselBannerEdit';
import OrderInvoice from './view/page/OrderInvoice';
import FlashsaleAdd from './view/page/FlashsaleAdd';
import Flashsale from './view/page/Flashsale';
import FlashsaleDetail from './view/page/FlashsaleDetail';
import FlashsaleEdit from './view/page/FlashsaleEdit';
import LoginPage from './view/page/LoginPage';
import axios from 'axios';
import { OrderDelivery } from './view/page/OrderDelivery';
import { Config } from './view/page/Config';
import { Courier } from './view/page/Courier';
import { Lottery } from './view/page/Lottery';
import SizeGroup from './view/page/SizeGroup';
import { VoucherProduct } from './view/page/VoucherProduct';
import { Roulette } from './view/page/Roulette';
import { RoulettePrize } from './view/page/RoulettePrize';
import { Raffle } from './view/page/Raffle';
import { RaffleDetail } from './view/page/RaffleDetail';
import { Employee } from './view/page/Employee';
import { RoleAccess } from './view/page/RoleAccess';
import { OrderReturn } from './view/page/OrderReturn';



function App() {
  const [collapsed, setCollapsed] = useState(false)
  const [log, setLog] = useState(null)
  const [idUser, setIdUser] = useState(null)
  const [access, setAccess] = useState([])
  const [userProf, setUserProf] = useState(null)

  const handleCollapsed = () => (setCollapsed(!collapsed))

  const handleLogOut = () => (
    axios({
      method: "POST",
      url: `${global.SRV}api_rudrunner_cms/controler/user.php/?index=3`,
      headers: { 'Content-Type': 'text/plain' },
      data: { id_user: idUser }
    })
      .then(res => {
        if (res.data.state === 200) {
          setLog(false)
          localStorage.removeItem('uid_rr')
          if (localStorage.getItem('remember') == 'false') localStorage.removeItem('email_rr')
        }
      })
  )

  global.IDR = new Intl.NumberFormat('IN', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  global.SRV = "https://cms.rudrunner.co.id/"

  useEffect(() => {
    const getAccessRole = (idUser) => {
      axios ({
        method: "POST",
        url: `${global.SRV}api_rudrunner_cms/controler/user.php/?index=5`,
        headers: { 'Content-Type': 'text/plain' },
        data: { id_user: idUser }
      })
      .then(res => { 
        if (res.data.length > 0){
          setAccess(res.data)
        }
      })
    }
      
    if (localStorage.getItem('uid_rr')) {
      setIdUser(localStorage.getItem('uid_rr'))
      setLog(true)
      getAccessRole(localStorage.getItem('uid_rr'))
    } else {
      setLog(false)
    }
  }, [localStorage.getItem('uid_rr'), log])

  useEffect(() => {
    const getUserProf = (id) => {
      axios ({
        method: "GET",
        url: `${global.SRV}api_rudrunner_cms/controler/user.php/?index=1&id_user=${id}`,
        headers: { 'Content-Type': 'text/plain' },
      })
      .then(res => { 
        if (res.data){
          setUserProf(res.data)
        }
      })
    }

    getUserProf(localStorage.getItem('uid_rr'))

  },[])
  
  return (
    <div className="App d-flex" style={{marginBottom: 'auto' }}>
      {
        !log && log !== null ?
          <LoginPage setLog={setLog} />
          :
          <div style={{display:'flex', width:'100%', direction:'ltr'}}>
            <aside>
            <SideBarMenu handleCollapsed={handleCollapsed} collapsed={collapsed} handleLog={handleLogOut} log={log} access={access} user={userProf}/>
            </aside>
            <main className='p-2 w-100 vh-100' style={{ overflowY: 'auto', marginBottom: 'auto' }}>
              <Routes>
                <Route exact path='/' element={<Dashboard />} />
                {
                  access.map((item, i) => {
                    switch (item.page_name){
                      case 'product' : return (<Route path='/product' element={<Product/>} key={i}/>);break;
                      case 'category' : return (<Route path='/category' element={<Category />} key={i}/>);break;
                      case 'product_add' : return (<Route path='/product_add' element={<ProductAdd />} key={i}/>);break;
                      case 'product_image' : return (<Route path='/product_image' element={<ProductImage />} key={i}/>); break;
                      case 'product_attribut' : return (<Route path='/product_attribute' element={<ProductAttrbute />} key={i}/>);break;
                      case 'category_sub' : return (<Route path='/category_sub' element={<CategorySub />} key={i}/>);break;
                      case 'category_add' : return (<Route path='/category_add' element={<CategoryAdd />} key={i}/>); break;
                      case 'category_sub_add' : return (<Route path='/category_sub_add' element={<CategorySubAdd />} key={i}/>); break;
                      case 'category_sub_edit' : return (<Route path='/category_sub_edit' element={<CategorySubEdit />} key={i}/>);break;
                      case 'product_edit' : return (<Route path='/product_Edit' element={<ProductEdit />} key={i}/>); break;
                      case 'product_attribute_edit' : return (<Route path='/product_attribute_edit' element={<ProductAttributeEdit />} key={i}/>);break;
                      case 'category_edit' : return (<Route path='/category_edit/' element={<CategoryEdit />} key={i}/>); break;
                      case 'color' : return (<Route path='/color' element={<Color />} key={i}/>);break;
                      case 'color_add' : return (<Route path='/color_add' element={<ColorAdd />} key={i}/>); break;
                      case 'customer' : return (<Route path='/customer' element={<Customer />} key={i}/>); break;
                      case 'customer_addr' : return (<Route path='/customer_addr' element={<CustomerAddr />} key={i}/>);break;
                      case 'order' : return (<Route path='/order' element={<Order />} key={i}/>);break;
                      case 'order_detail' : return (<Route path='/order_detail' element={<OrderDetail />} key={i}/>);break;
                      case 'order_return' : return (<Route path='/order_return' element={<OrderReturn />} key={i}/>); break;
                      case 'voucher' : return (<Route path='/voucher' element={<Voucher />} key={i}/>);break;
                      case 'voucher_add' : return (<Route path='/voucher_add' element={<VoucherAdd />} key={i}/>); break;
                      case 'voucher_edit' : return (<Route path='/voucher_edit' element={<VoucherEdit />} key={i}/>);break;
                      case 'voucher_special' : return (<Route path='/voucher_special' element={<VoucherSpecial />} key={i}/>);break;
                      case 'voucher_special_add' : return (<Route path='/voucher_special_add' element={<VoucherSpecialAdd />} key={i}/>); break;
                      case 'voucher_special_edit' : return (<Route path='/voucher_special_edit' element={<VoucherSpecialEdit />} key={i}/>);break;
                      case 'carousel_banner' : return (<Route path='/carousel_banner' element={<CarouselBanner />} key={i}/>);break;
                      case 'carousel_banner_add' : return (<Route path='/carousel_banner_add' element={<CarouselBannerAdd />} key={i}/>);break;
                      case 'carousel_banner_edit' : return (<Route path='/carousel_banner_edit' element={<CarouselBannerEdit />} key={i}/>);break;
                      case 'order_invoice' : return (<Route path='/order_invoice' element={<OrderInvoice />} key={i}/>);break;
                      case 'flashsale_add' : return (<Route path='/flashsale_add' element={<FlashsaleAdd />} key={i}/>);break;
                      case 'flashsale_edit' : return (<Route path='/flashsale_edit' element={<FlashsaleEdit />} key={i}/>);break;
                      case 'flashsale' : return ( <Route path='/flashsale' element={<Flashsale />} key={i}/>);break;
                      case 'flashsale_detail' : return (<Route path='/flashsale_detail' element={<FlashsaleDetail />} key={i}/>);break;
                      case 'order_delivery' : return (<Route path='/order_delivery' element={<OrderDelivery />} key={i}/>);break;
                      case 'config' : return (<Route path='/config' element={<Config />} key={i}/>);break;
                      case 'courier' : return (<Route path='/courier' element={<Courier />} key={i}/>);break;
                      case 'lottery' : return (<Route path='/lottery' element={<Lottery />} key={i}/>);break;
                      case 'sizegroup' : return (<Route path='/size_group' element={ <SizeGroup/>} key={i}/>);break;
                      case 'voucher_product' : return (<Route path='/voucher_product' element={ <VoucherProduct/>} key={i}/>);break;
                      case 'roulette' : return (<Route path='/roulette' element={ <Roulette/>} key={i}/>);break;
                      case 'prize_list' : return (<Route path='/roulette_prize' element={ <RoulettePrize/>} key={i}/>);break;
                      case 'raffle' : return (<Route path='/raffle' element={ <Raffle/>} key={i}/>);break;
                      case 'raffle_detail' : return (<Route path='/raffle/raffle_detail' element={ <RaffleDetail/>} key={i}/>);break;
                      case 'employee' : return (<Route path='/administrator/employee' element={ <Employee/>} key={i}/>);break;
                      case 'role_access' : return (<Route path='/administrator/role' element={ <RoleAccess/>} key={i}/>);break;
                    }
                  })
                }
              </Routes>
            </main>
          </div>
      }
    </div>
  );
}

export default App;
