import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row} from "react-bootstrap";
import { Button, Dimmer, Divider, Select, Form, Loader, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import { Toast } from "../component/Toast";

export const RoleAccess = () => {
    const [dataRole, setDataRole] = useState([])
    const [dataAccess, setDataAccess] = useState([])
    const [modalAccess, setModalAccess] = useState(false)
    const [loadAccessData, setloadAccessData] = useState(false)
    const [modalAddAccess, setModalAddAccess] = useState(false)
    const [optAcc, setOptAcc] = useState([])
    const [selPage, setSelPage] = useState([])
    const [selRole, setSelRole] = useState([])
    const [modalAddRole, setModalAddRole] = useState(false)
    const [loadAddRole, setLoadAddRole] = useState(false)
    const [newRole, setNewRole] = useState('')
    const [loadDelRole, setLoadDelRole] = useState({})
    const [loadRemAcs,setLoadRemAcs] = useState({})

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_role"
        },
        {
            Header: "Role Name",
            accessor: "role_name"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="tiny" basic>
                    <Popup
                        content="Add access page to this role"
                        trigger={
                            <Button icon="plus square outline" onClick={() => handleModalAddAccess(cell.row.original)} />
                        }
                    />
                    <Popup
                        content="View Access"
                        trigger={
                            <Button icon="tasks" onClick={() => handleViewAccess(cell.row.original)} />
                        }
                    />
                    <Popup
                        content="Remove this role"
                        trigger={
                            <Button icon="trash alternate" loading={loadDelRole.id === cell.row.original.id_role && loadDelRole.status} disabled={loadDelRole.id === cell.row.original.id_role && loadDelRole.status} onClick={() => deleteRole(cell.row.original.id_role)}/>
                        }
                    />
                </Button.Group>
            )
        }
    ])

    const columnsAccess = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_page"
        },
        {
            Header: "Page Name",
            accessor: "page_name"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="tiny">
                    <Popup
                        size="mini"
                        content="Remove access to this page"
                        trigger={
                            <Button 
                                icon="eraser" 
                                loading={loadRemAcs.id === cell.row.original.id_page && loadRemAcs.status}
                                disabled={loadRemAcs.id === cell.row.original.id_page && loadRemAcs.status}
                                onClick={() => removeAccessPage(cell.row.original)}
                            />
                        }
                    />
                </Button.Group>
            )
        },
    ])

    const handleModalAddAccess = (data) => {
        getDataAccessNotInRole(data.id_role)
        setModalAddAccess(true)
        setSelRole(data)
    }

    const handleViewAccess = (role) => {
        setloadAccessData(true)
        setModalAccess(true)
        getDataAccess(role.id_role)
        setSelRole(role)
    }

    const handleChangeAccess = (e, {value}) => {
        setSelPage(value)
    }

    const handleSubmitAddPage = () => {
        const data = selPage?.map(i => {
            return {
                id_role:selRole.id_role,
                id_page:i,
            }
        })

        addAccess(data)
    }

    const addAccess = (arrAccess) => {
        const param = arrAccess
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=9`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: param
        })
        .then (res => {
            if (res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text:'Success adding access page',
                    position:'top-right'
                })
                setModalAddAccess(false)
                setSelPage([])
            } else if (res.data.state === 500) {
                Toast.fire({
                    icon:'error',
                    text:'Failed adding access page',
                    position:'top-right'
                })
            } else if (res.data.state === 300){
                Toast.fire({
                    icon:'warning',
                    text:'No access selected, select it first',
                    position:'top-right'
                })
            }
        })
    }

    const getDataAccess = async (id) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=7&id_role=${id}`,
        }).
            then(res => {
                setloadAccessData(false)
                if (res.data.state === 200) {
                    setDataAccess(res.data.data)
                }
            })
    }

    const getDataAccessNotInRole = async (id) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=8&id_role=${id}`,
        }).
            then(res => {
                if (res.data.state === 200) { 
                    setOptAcc(res.data.data.map((item, i) => {
                        return {
                            key: item.id_page,
                            text: item.page_name,
                            value: item.id_page,
                        }
                    })
                    )
                }
            })
    }
 
    const getDataRole = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=6`,
        }).
            then(res => {
                if (res.data.state === 200) {
                    setDataRole(res.data.data)
                }
            })
    }

    const submitNewRole = () => {
        setLoadAddRole(true)
        const param = new URLSearchParams()
        param.append('role', newRole)
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=10`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
        .then (res => {
            setLoadAddRole(false)
            if (res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text:'Success adding new role',
                    position: 'top-right',
                })
                getDataRole()
                setNewRole('')
                setModalAddRole(false)
            } else {
                Toast.fire({
                    icon:'error',
                    text:'Failed adding new role',
                    position: 'top-right',
                })
            }
        })
    }

    const deleteRole = (idRole) => {
        const param = new URLSearchParams()
        param.append('id_role', idRole)
        const msg = window.confirm("Sure you want to delete this role?")
        if (msg){
            setLoadDelRole({
                id:idRole,
                status:true
            })
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=11`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: param
            })
            .then (res => {
                setLoadDelRole({
                    id:idRole,
                    status:false
                })
                if (res.data.state === 200){
                    Toast.fire({
                        icon:'success',
                        text:'Success delete role',
                        position:'top-right'
                    })
                    getDataRole()
                } else {
                    Toast.fire({
                        icon:'error',
                        text:'Failed delete role',
                        position:'top-right'
                    })
                }
            })
        }
    }

    const removeAccessPage = (data) => {
        const param = new URLSearchParams()
        const msg = window.confirm("Sure you want to remove access page?")

        param.append('id_page', data.id_page)
        param.append('id_role', data.id_role)
        
        if (msg){
            setLoadRemAcs({
                id:data.id_page,
                status:true
            })
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/admin.php/?index=12`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: param
            })
            .then(res => {
                setLoadRemAcs({
                    id:data.id_page,
                    status:false
                })
                if (res.data.state === 200){
                    Toast.fire({
                        icon:'success',
                        text:'Success remove access to page',
                        position:'top-right'
                    })
                    getDataAccess()
                    setModalAccess(false)
                } else {
                    Toast.fire({
                        icon:'error',
                        text:'Failed remove access to page',
                        position:'top-right'
                    })
                }
            })
        }

    }

    useEffect(() => {
        getDataRole()
    }, [])
    
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Access Role</div>
                <div className="text-muted">List of rule groups and their access rights</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={10} md={12} sm={12}>
                    <Button content="New Role" color="facebook" icon="add" onClick={() => setModalAddRole(true)}/>
                    <Table columns={columns} data={dataRole} pageRow={10} />
                </Col>
            </Row>

            <Modal show={modalAccess} onHide={() => setModalAccess(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Access List</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="fw-bold">Selected Role : {selRole.role_name}</div>
                    <Dimmer active={loadAccessData} inverted>
                        <Loader inverted content='Loading' />
                    </Dimmer>
                    <Table data={dataAccess} columns={columnsAccess} pageRow={10} />
                </Modal.Body>
            </Modal>

            <Modal show={modalAddAccess} onHide={() => setModalAddAccess(false)} keyboard={false} backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Adding Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 fw-bold">Role : {selRole.role_name}</div>
                    <Form widths="equal" onSubmit={handleSubmitAddPage}>
                        <Form.Dropdown 
                            required
                            search
                            control={Select}
                            options={optAcc} 
                            label='Select Page'
                            multiple
                            fluid
                            clearable
                            onChange={handleChangeAccess}
                        />
                        <div className="text-end">
                            <Button content="Add Access" icon="add" color="facebook"/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={modalAddRole} onHide={() => setModalAddRole(false)} keyboard={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Add New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={submitNewRole}>
                        <Form.Input type="text" required value={newRole} label="Role Name"  onChange={(e) => setNewRole(e.target.value)}/>
                        <div className="text-end">
                            <Button content="Add Rule" loading={loadAddRole} disabled={loadAddRole} icon="add" type="submit" color="facebook"/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}