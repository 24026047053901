import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Image, Modal, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Button, Form, Popup, Input, Label, Icon } from "semantic-ui-react";
import { Toast } from "../component/Toast";
import Table from "../component/Table";

export const RaffleDetail = () => {
    const location = useLocation().state
    const [productsRaffle, setProductsRaffle] = useState([])
    const [modalProduct, setModalProduct] = useState(false)
    const [products, setProducts] = useState([])
    const [maxPar, setMaxPar] = useState(100)
    const [selProduct, setSelProduct] = useState('')
    const [modalSize, setModalSize] = useState(false)
    const [selectSize, setSelectSize] = useState({})
    const [dataSize, setDataSize] = useState([])
    const [selectArt, setSelectArt] = useState({})
    const [participantPerSize, setParticipantPerSize] = useState([])
    const [preselectWinner, setPreselectWinner] = useState([])
    const [numberWinner, setNumberWinner] = useState(0)
    const [modalChangeWinner, setModalChangeWinner] = useState(false)
    const [optionParticipant, setOptionParticipant] = useState([])
    const [altWinner, setAltWinner] = useState('')
    const [winnerToChange, setWinnerToChange] = useState('')
    const [btnSubState, setBtnSubState] = useState(false)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [modalWinner, setModalWinner] = useState(false)
    const [winnerBySize, setWinnerBySize] = useState([])
    const [isRandomed, setIsRandomed] = useState(false)
    const [loadWinnerSize, setLoadWinnerSize] = useState(false)
    const [loadResend, setLoadResend] = useState(false)

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_customer"
        },
        {
            Header: "Name",
            accessor: null,
            Cell: ({ cell }) => (
                <div>
                    <span className="me-1">{cell.row.original.first_name}</span>
                    <span>{cell.row.original.last_name}</span>
                </div>
            )
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Pot Winner",
            accessor: "pot_winner",
            Cell: ({ cell }) => (cell.value && cell.value == 'Y' ? <Icon name="flag checkered" color="green" /> : '')
        }
    ])

    const getRaffleProduct = async (idRaffle) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=7&id_raffle=${idRaffle}`,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.data.state === 200) {
                    setProductsRaffle(res.data.data)
                }
            })
    }

    const getProducts = async () => {
        await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=36")
            .then(res => {
                if (res.data.state === 200) {
                    let data = res.data.data
                    let opt = []

                    data.map((item) => {
                        opt.push(
                            {
                                key: item.id_product,
                                value: item.id_product,
                                text: item.id_product + ' | ' + item.product_name,
                                name: item.product_name,
                                qty: item.total_qty,
                                image: <Image src={item.image} rounded className="border" />,
                            }
                        )
                    })
                    setProducts(opt)
                }
            })
    }

    const handleClickAdd = () => {
        setModalProduct(true)
    }

    const handleChangeProduct = (e, { value }) => {
        let qty = products.filter((i) => i.value === value)[0]['qty']
        let name = products.filter((i) => i.value === value)[0]['name']
        let image = products.filter((i) => i.value === value)[0]['image'].props.src

        setSelProduct({
            id: value,
            image: image,
            name: name,
            qty: qty,
        })
    }

    const handleSubmit = () => {
        const exist = productsRaffle?.some(i => i.id_raffle === location.id_raffle && i.id_product === selProduct.id)

        if (!exist) {
            const param = {
                id_raffle: location.id_raffle,
                id_product: selProduct.id,
                name: selProduct.name,
                qty: selProduct.qty,
                max_participant: maxPar,
                image: selProduct.image
            }
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=14`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            }).
                then(res => {
                    if (res.data.state === 200) {
                        getRaffleProduct(location.id_raffle)
                        setModalProduct(false)
                        Toast.fire({
                            icon: 'success',
                            text: 'Product successfully add',
                            position: 'top-right'
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed to add product',
                            position: 'top-right'
                        })
                    }
                })
        } else {
            Toast.fire({
                icon: "warning",
                text: "This product has been added",
                position: 'top-right'
            })
        }
    }

    const handleRemove = (data) => {
        let msg = window.confirm('Sure want to remove this product?')

        if (msg) {
            const param = {
                id_product: data.id_product,
                id_raffle: data.id_raffle,
            }
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=15`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            }).
                then(res => {
                    if (res.data.state === 200) {
                        getRaffleProduct(location.id_raffle)
                        Toast.fire({
                            icon: "success",
                            text: "Selected product removed",
                            position: "top-right"
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed to remove product',
                            position: 'top-right'
                        })
                    }
                })
        }
    }

    const getSize = (id_product) => {
        const param = new URLSearchParams()
        param.append('id_product', id_product)
        axios({
            method: "POST",
            url: `https://hoopsdevteam.com/api_web/controler/cms_rr.php?index=37`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        }).
            then(res => {
                if (res.data.state === 200) {
                    setDataSize(res.data.data)
                }
            })
    }

    const getRaffleParticipant = (id_raffle, barcode) => {
        const param = new URLSearchParams()

        param.append('id_raffle', id_raffle)
        param.append('barcode', barcode)

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=20`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        }).
            then(res => {
                if (res.data.state === 200) {
                    setParticipantPerSize(res.data.data)
                    setIsRandomed(res.data.data.some(i => i.pot_winner === 'Y'))
                    setPreselectWinner(res.data.data.filter(i => i.pot_winner === 'Y'))
                }
            })
    }

    const handleChangeSize = (dataSize) => {
        setSelectSize(dataSize)
        setNumberWinner(dataSize.qty)
        // getRaffleParticipant(location.id_raffle, dataSize.barcode)
        setPreselectWinner([])
    }

    const handleShowRandom = (data) => {
        const id = data.id_product
        setSelectArt(data)
        setModalSize(true)
        getSize(id)
        setPreselectWinner([])
        setSelectSize({})
    }

    const handleModalChangeWinner = (customer) => {
        setModalChangeWinner(true)
        setWinnerToChange(customer)
    }

    const handleChangeWinner = (idCustomer) => {
        const alternateWinner = participantPerSize.find(i => i.id_customer === idCustomer)
        const newData = preselectWinner.map((item, i) => {
            if (item.id_customer === winnerToChange.id_customer) {
                const exist = preselectWinner.some(p => p.id_customer === alternateWinner.id_customer)
                if (!exist) {
                    Toast.fire({
                        icon: 'success',
                        text: 'Success replacing customer',
                        position: 'top-right'
                    })
                    setModalChangeWinner(false)
                    return Object.assign({}, item, alternateWinner)
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: 'The winners are already on the list...',
                        position: 'top-right'
                    })
                    return item
                }
            } else {
                return item
            }
        })

        const potWinnerFlag = participantPerSize.map(item1 => {
            const match = newData.find(item2 => item2.id_customer === item1.id_customer)
            if (match) {
                return { ...item1, pot_winner: true }
            } else {
                return { ...item1, pot_winner: false }
            }
        });

        setPreselectWinner(newData)
        setParticipantPerSize(potWinnerFlag)
    }

    const handleRemovePreWinner = (idCustomer) => {
        const filtered = preselectWinner.filter(i => i.id_customer !== idCustomer)
        setPreselectWinner(filtered)

        const potWinnerFlag = participantPerSize.map(item1 => {
            const match = filtered.find(item2 => item2.id_customer === item1.id_customer)
            if (match) {
                return { ...item1, pot_winner: true }
            } else {
                return { ...item1, pot_winner: false }
            }
        });

        setParticipantPerSize(potWinnerFlag)
    }

    const handleRandom = (count) => {
        console.log(participantPerSize)
        if (!isRandomed) {
            if (numberWinner > 0) {
                const dataCopy = [...participantPerSize];
                const randomData = []
                for (let i = 0; i < count; i++) {
                    const remainingDataLength = dataCopy.length
                    if (remainingDataLength === 0) break
                    const randomIndex = Math.floor(Math.random() * remainingDataLength)
                    const selectedData = dataCopy[randomIndex]
                    randomData.push(selectedData)
                    dataCopy.splice(randomIndex, 1)

                    randomData[i].size = selectSize.size
                    randomData[i].barcode = selectSize.barcode
                    randomData[i].id_raffle = location.id_raffle
                }

                setPreselectWinner(randomData)

                let opt = []
                // const potWinner = participantPerSize.filter(i => {
                //     const isFound = preselectWinner.some(o => i.id_customer === o.id_customer)
                //     if (!isFound) {
                //         return i
                //     }
                // })
                participantPerSize.map((item, i) => {
                    opt.push({
                        key: i,
                        text: item.email,
                        value: item.id_customer,
                    })
                })

                const potWinnerFlag = participantPerSize.map(item1 => {
                    const match = randomData.find(item2 => item2.id_customer === item1.id_customer);
                    if (match) {
                        return { ...item1, pot_winner: 'Y' };
                    } else {
                        return { ...item1, pot_winner: 'N' };
                    }
                });

                setOptionParticipant(opt)
                setParticipantPerSize(potWinnerFlag)

            } else {
                Toast.fire({
                    icon: 'warning',
                    text: "Item quantity is 0 or you haven't set the winning quantity",
                    position: 'center'
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                text: "It's been drawn",
                position: 'center'
            })
        }
    }

    const handleShowModalWinner = (data) => {
        setWinnerBySize([])
        setSelectArt(data)
        setModalWinner(true)
        getSize(data.id_product)
    }

    const handleChangeSizeWinner = (data) => {
        setSelectSize(data)
    }

    const handleClickSubmit = () => {
        const msg = window.confirm("Are you sure, Make sure everything is correct...")

        if (msg) {
            setLoadSubmit(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=21`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: preselectWinner
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setModalSize(false)
                        setLoadSubmit(false)
                        Toast.fire({
                            icon: 'success',
                            text: 'Success submit Winner for selected product & size',
                            position: 'top-right',
                        })
                    } else if (res.data.state === 500) {
                        setLoadSubmit(false)
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed submit Winner for selected product & size',
                            position: 'top-right',
                        })
                    } else {
                        setLoadSubmit(false)
                    }
                })
        }
    }

    const getWinnerBySize = (idRaffle, barcode) => {
        setLoadWinnerSize(true)
        axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=8&id_raffle=${idRaffle}&barcode=${barcode}`,
        })
            .then(res => {
                if (res.data.state === 200) {
                    setWinnerBySize(res.data.data)
                    setLoadWinnerSize(false)
                }
            })
    }

    const handleResend = (data) => { 
        const msg = window.confirm("Do you want to resend email for this winner?")
        const param = data
       
        if (msg){
            setLoadResend(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=23`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: param
            })
            .then (res => {
                setLoadResend(false)
                if (res.data.state === 200){
                    Toast.fire({
                        icon:'success',
                        text:'Email successfully sent..',
                        position:'top-right'
                    })
                }
            })
        }
    }

    useEffect(() => {
        getRaffleProduct(location.id_raffle)
        getProducts()
    }, [location])

    useEffect(() => {
        getRaffleParticipant(location.id_raffle, selectSize.barcode)
        getWinnerBySize(location.id_raffle, selectSize.barcode)
    }, [selectSize])

    useEffect(() => {
        if (preselectWinner.length === 0) {
            setBtnSubState(true)
        } else {
            setBtnSubState(false)
        }
    }, [preselectWinner])
    console.log(location.is_published)
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Raffle Products</div>
                <div className="text-muted">This list of product that available for this raffle</div>
            </Row>
            <Divider />
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Button content="Add Product" icon="plus" color="facebook" disabled={location.is_published === 'N' ? false : true} onClick={handleClickAdd} />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <table className="table">
                        <thead className="bg-light">
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>ID Product</th>
                                <th>Product Name</th>
                                <th>Qty</th>
                                {/* <th>Max Participant</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productsRaffle?.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td><Image src={item.img} width={40} height={35} rounded /></td>
                                        <td>{item.id_product}</td>
                                        <td>{item.name}</td>
                                        <td>{item.qty}</td>
                                        {/* <td>{item.max_participant}</td> */}
                                        <td>
                                            <Button.Group basic size="tiny">
                                                <Popup
                                                    content="Randomize Raffle Winner for this Product"
                                                    trigger={<Button icon="random" disabled={item.is_published === 'N' ? false : true}
                                                    onClick={() => handleShowRandom(item)} />}
                                                />
                                                <Popup
                                                    content="List the winner of this product"
                                                    trigger={<Button icon="trophy" onClick={() => handleShowModalWinner(item)} />}
                                                />
                                                <Popup
                                                    content="Remove Product"
                                                    trigger={<Button icon="trash" onClick={() => handleRemove(item)} />}
                                                />
                                            </Button.Group>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>

            <Modal show={modalProduct} onHide={() => setModalProduct(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Select
                            required
                            search
                            // control={Select}
                            options={products}
                            label={{ children: 'Products' }}
                            onChange={handleChangeProduct}
                        />
                        <Form.Input label="Maximum Participant" type="number" value={maxPar} onChange={(e) => setMaxPar(e.target.value)} />
                        <div className="text-end mt-4">
                            <Button content="Add This Product" color="facebook" />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={modalSize} onHide={() => setModalSize(false)} size="lg" style={{ fontSize: '0.9em' }} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5"><Icon name="random" />&nbsp;Randomize Raffle Participant</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'auto', height: '75vh' }}>
                    <Stack direction="horizontal" gap={2} className="mb-4">
                        <Image src={selectArt.img} width={60} height={55} rounded className="border" />
                        <Stack direction="vertical" gap={0}>
                            <div className="text-muted fw-bold" style={{ fontSize: '0.9em' }}>{selectArt.id_product}</div>
                            <div>{selectArt.name}</div>
                        </Stack>
                    </Stack>
                    <Card>
                        <Card.Body>
                            <div className="fw-bold">Please Select one Size to randomize Raffle</div>
                            <div className="mb-3">Selected :
                                <span className="ms-2 me-3">Size: {selectSize.size}</span>
                                <span className="me-3">Qty: {selectSize.qty}</span>
                                <span className="me-3">Number of Winner : &nbsp;
                                    <input
                                        type="number"
                                        size="tiny"
                                        value={numberWinner}
                                        style={{ width: '50px' }}
                                        onChange={(e) => setNumberWinner(e.target.value)}
                                    />
                                </span>
                            </div>
                            <Form>
                                <Form.Group inline>
                                    {
                                        dataSize?.map((size, i) => (
                                            <Form.Radio
                                                style={{ fontSize: '0.9em' }}
                                                key={i}
                                                label={size.size + ' ' + '(' + size.qty + ')'}
                                                value={size.size}
                                                checked={selectSize.barcode === size.barcode}
                                                onChange={(e, { value }) => handleChangeSize(size)}
                                            />
                                        ))
                                    }
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Divider horizontal style={{ fontSize: '0.9em' }}>Raffle Participant&nbsp;({participantPerSize.length})</Divider>
                    <div className="mb-4">
                        <Card>
                            <Card.Body>
                                <Table columns={columns} data={participantPerSize} pageRow={5} />
                            </Card.Body>
                            <Card.Footer>
                                <div className="mb-1 mt-1 text-start">
                                    <Button content="Randomize Participant" disabled={isRandomed} icon="random" color="black" onClick={() => handleRandom(numberWinner)} />
                                </div>
                            </Card.Footer>
                        </Card>
                    </div>
                    <div>
                        <Card>
                            <Card.Body>
                                <div className="text-uppercase fw-bold mb-3">Randomed Participant</div>
                                <table className="table" >
                                    <thead className="bg-light">
                                        <tr>
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            preselectWinner.length > 0 ?
                                                preselectWinner.map((item, i) =>
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{item.id_customer}</td>
                                                        <td>{item.first_name + ' ' + item.last_name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        <td className="text-end">
                                                            <Button.Group basic size="tiny">
                                                                <Popup
                                                                    content="Change Selected Winner"
                                                                    trigger={
                                                                        <Button icon='exchange' onClick={() => handleModalChangeWinner(item)} />
                                                                    }
                                                                />
                                                                <Popup
                                                                    content="Remove Selected Winner"
                                                                    trigger={
                                                                        <Button icon='trash' onClick={() => handleRemovePreWinner(item.id_customer)} />
                                                                    }
                                                                />
                                                            </Button.Group>
                                                        </td>
                                                    </tr>
                                                )
                                                : <tr>
                                                    <td colSpan="6" className="text-center"><Icon name="exclamation circle" /> No Data ...</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        loading={loadSubmit}
                        disabled={btnSubState}
                        content="Submit Winner"
                        icon="check"
                        labelPosition="right"
                        color="facebook"
                        onClick={() => handleClickSubmit()}
                    />
                </Modal.Footer>
            </Modal>

            <Modal show={modalChangeWinner} onHide={() => setModalChangeWinner(false)} backdrop="static" keyboard={false} centered >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">Change Winner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">Winner to Replace : {winnerToChange.email}</div>
                    <Form className="mb-3">
                        <Form.Dropdown
                            label="Replaced by"
                            search
                            fluid
                            selection
                            options={optionParticipant}
                            onChange={(e, { value }) => setAltWinner(value)}
                        />
                        <div className="text-end">
                            <Button
                                type="submit"
                                content="Apply Change"
                                color="facebook"
                                onClick={() => handleChangeWinner(altWinner)}
                            />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={modalWinner} onHide={() => setModalWinner(false)} size="lg" style={{ fontSize: '0.9em' }}>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5"><Icon name="winner" />&nbsp;Raffle Winner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Stack direction="horizontal" gap={2} className="mb-4">
                                <Image src={selectArt.img} width={60} height={55} rounded className="border" />
                                <Stack direction="vertical" gap={0}>
                                    <div className="text-muted fw-bold" style={{ fontSize: '0.9em' }}>{selectArt.id_product}</div>
                                    <div>{selectArt.name}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div>Select Size : </div>
                            <Form>
                                <Form.Group inline>
                                    {
                                        dataSize?.map((size, i) => (
                                            <Form.Radio
                                                style={{ fontSize: '0.9em' }}
                                                key={i}
                                                label={size.size}
                                                value={size.size}
                                                checked={selectSize.barcode === size.barcode}
                                                onChange={(e, { value }) => handleChangeSizeWinner(size)}
                                            />
                                        ))
                                    }
                                </Form.Group>
                            </Form>
                            <Divider horizontal style={{ fontSize: '0.9em' }}>Winner for size {selectSize.size} ({winnerBySize.length})</Divider>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <table className="table">
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Member</th>
                                        <th>Gender</th>
                                        <th>Email Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {winnerBySize.length > 0 ?
                                        winnerBySize.map((item, i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{item.id_customer}</td>
                                                <td>
                                                    <Stack direction="horizontal" gap={2}>
                                                        <Image src={item.avatar ? item.avatar : `https://ui-avatars.com/api/?name=${item.first_name}+${item.last_name}`} width={36} height={36} roundedCircle />
                                                        <Stack direction="vertical" gap={0}>
                                                            <div>{item.first_name + ' ' + item.last_name}</div>
                                                            <div>{item.email}</div>
                                                        </Stack>
                                                    </Stack>
                                                </td>
                                                <td>{item.phone}</td>
                                                <td>{item.isMEmber === 'Y' ? <><Icon name="check circle outline" color="blue"/>Yes</> : <><Icon name="ban" />No</>}</td>
                                                <td>{item.gender === 'M' ? <Icon name="male" size="large" color="blue" /> : <Icon name="female" size="large" color="pink" />}</td>
                                                <td><Icon name="check circle outline" color="blue"/>{item.email_state}</td>
                                                <td>
                                                    <Button.Group basic size="tiny">
                                                        <Popup
                                                            size="mini"
                                                            content="Resend Email"
                                                            trigger={
                                                                <Button
                                                                    icon="paper plane outline"
                                                                    disabled={loadResend}
                                                                    loading={loadResend}
                                                                    onClick={()=> handleResend(item)}
                                                                />
                                                            }
                                                        />
                                                    </Button.Group>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        !loadWinnerSize && winnerBySize.length === 0 ?
                                            <tr>
                                                <td colSpan={8} className="text-center"><Icon name="info circle" />No Data | No Size Selected</td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={8} className="text-center"><Icon name="spinner" loading />Loading...</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    )
}