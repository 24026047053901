import axios from "axios";
import React, {useState, useEffect} from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Divider, Image } from "semantic-ui-react";


const CategorySubEdit = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [image, setImage] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [categoryName, setCategoryName] = useState('')
    const [position, setPosition] = useState(0)
    const [data, setData] = useState(null)
    const [alertShow, setAlertShow] = useState(false)
    const [idParent, setIdParent] = useState(null)
    const [playerSign, setPlayerSign] = useState('0')

    const handleSubmit = () => {
        const data = new FormData()
        const endpoint = "https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=26"

        data.append('id_category_child', location.state.id_category_child)
        data.append('category_name', categoryName)
        data.append('position', position)
        data.append("image", image)
        data.append("thumbnail", thumbnail)
        data.append("is_ps", playerSign)

        axios.post(endpoint, data)
            .then(res => {
                console.log(res.data)
                if (res.data.state === 200){
                    navigate('/category_sub', {state:{ID:idParent}})
                }
            })
    }

    const hanndleChangeCheck = (e) => { 
        setPlayerSign(e == true ? '1' : '0')
    }

    const handleInputChangeImage = (e) => {
        let image = e.target.files
        setImage(image[0])
    }

    const handleInputChangeThumbnail = (e) => {
        let image = e.target.files
        setThumbnail(image[0])
    }

    useEffect(() => {
        setCategoryName(location.state.category_name)
        setPosition(location.state.position)
        setImage(location.state.header_image)
        setThumbnail(location.state.thumbnail_image != 'null' ? location.state.thumbnail_image : 'https://via.placeholder.com/150x150.png')
        setIdParent(location.state.id_category)
        setPlayerSign(location.state.is_ps)
    }, [location.state])
    console.log(location.state)
    return(
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Edit Sub Category</div>
                <div className="text-muted">You can Edit a sub category for category {data && data.category_name} here, which will be displayed on the website</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center">
                <Col sm={12} md={12} lg={8} xl={6}>
                    <Alert variant="success" onClose={() => setAlertShow(false)} show={alertShow} dismissible>
                        <p>
                            Success, adding new sub category product
                        </p>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-3 d-flex justify-content-center">
                <Col sm={12} md={12} lg={8} xl={6}>
                    <Form widths="equal" onSubmit={(e) => handleSubmit(e)}>
                        <Form.Input label="Category name" value={categoryName} onChange={(e, {value}) => setCategoryName(value)} required/>
                        <Form.Input label="Position" value={position} onChange={(e, {value}) => setPosition(value)} type="number" />
                        <Image src={image} style={{height:100}}/>
                        <Form.Input
                            label="Image"
                            type="file"
                            icon="image"
                            className="mb-0 mt-3"
                            onChange={(e) => handleInputChangeImage(e)}
                        />
                        <small className="text-muted">Images must be in *.png format and file size no more than 500kb</small>
                        <Image src={thumbnail} style={{height:100}}/>
                        <Form.Input
                            label="Images Thumbnail"
                            type="file"
                            icon="image"
                            className="mb-0 mt-3"
                            onChange={(e) => handleInputChangeThumbnail(e)}
                        />
                         <small className="text-muted">Images must be in *.png format and file size no more than 250kb</small>
                         <Form.Group className="mt-3">
                            <Form.Checkbox 
                                label="Player Signature" 
                                defaultChecked={playerSign == 0 ? false : true} 
                                checked = {playerSign == '0' ? false : true}
                                value={playerSign == '0' ? false : true}
                                onChange={(e, {checked}) => hanndleChangeCheck(checked)}
                            />
                        </Form.Group>
                        <Form.Button content="Update Category" className="mt-5" color="facebook" />
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default CategorySubEdit