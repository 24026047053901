import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Divider, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import { useState } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Toast } from "../component/Toast";

const Flashsale = () => {
    const [flashsale, setFlashsale] = useState([])
    const navigate = useNavigate()

    const columns = useMemo(() => [
        {
            Header:"ID",
            accessor:"id_fs"
        },
        {
            Header:"Name",
            accessor:"name"
        },
        {
            Header:"Description",
            accessor:"description"
        },
        // {
        //     Header:"Discount",
        //     accessor:"discount_value"
        // },
        {
            Header:"Start",
            accessor:"date_start"
        },
        {
            Header:"End",
            accessor:"date_end"
        },
        {
            Header:"Active",
            accessor:"is_active"
        },
        {
            Header:"Action",
            Cell:(cell) => (
                <Fragment>
                    <Button.Group size="mini" basic>
                        <Popup content="Products" trigger={<Button icon="box" size="mini" onClick={() => navigate('/flashsale_detail', {state:cell.row.values})}/>} />
                        <Popup content="Delete" trigger={<Button icon="eraser" size="mini" onClick={() => handleDelete(cell.row.values)}/>} />
                        <Popup content="Edit" trigger={<Button icon="edit" size="mini" onClick={() => navigate('/flashsale_edit', {state:cell.row.values})}/>} />
                    </Button.Group>
                </Fragment>
            )
        }
    ],[])


    const getFlashsale = async () => {
        await axios.get(`${global.SRV}api_rudrunner_cms/controler/event.php/?index=1`)
        .then (res => {
            if (res.data.state === 200){
                setFlashsale(res.data.data)
            }
        })
    }

    const handleDelete = (selected) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3b5998',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios ({
                    method:"POST",
                    url:`${global.SRV}api_rudrunner_cms/controler/event.php/?index=5`,
                    headers:{'Content-Type': 'text/plain'},
                    data:selected
                })
                .then (res => {
                    if (res.data.state === 200){
                        Toast.fire({
                            icon:'success',
                            text:res.data.message,
                            position:'top-end'
                        })
                        getFlashsale()
                    }
                })
            }
        })
    }

    const handleClickAdd = () => (navigate('/flashsale_add'))

    useEffect(() => {
        getFlashsale()
    },[])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Flashsale</div>
                <div className="text-muted">List of Flashsale Event, you must add several product to each Flashsale </div>
            </Row>
            <Divider />
            <Row className="mb-3">
                <Col md={12} xs={12} className="d-flex justify-content-end">
                    <Button content="New Flashsale" color="facebook" size="small" onClick={handleClickAdd}/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col md={12} xl={12} sm={12}>
                    <Table columns={columns} data={flashsale}/>
                </Col>
            </Row>
        </Container>
    )
}

export default Flashsale;