import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { Col, Row } from 'react-bootstrap';

const DynamicTable = ({ rowData, columnDefs, loading, exportFileName }) => {
  const gridRef = useRef(null);
  const [initialState, setInitialState] = useState();
  const [currentState, setCurrentState] = useState();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  columnDefs.forEach((column) => {
    if (column.field === 'action' || column.field === 'image' || column.field === 'no') {
      column.sortable = false;
    }
  });

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 50,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
    };
  }, []);

  const onGridPreDestroyed = useCallback((params) => {
    const { state } = params;
    console.log('Grid state on destroy (can be persisted)', state);
    setInitialState(JSON.parse(localStorage.getItem('grid_state')));
  }, []);

  const onStateUpdated = useCallback((params) => {
    console.log('State updated', params.state);
    setCurrentState(params.state);
    localStorage.setItem('grid_state', JSON.stringify(params.state))
  }, []);


  const onBtnExport = useCallback(() => {
    const exportParams = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true, // Export semua kolom
      onlySelected: false,
      suppressQuotes: false,
      fileName: exportFileName,
      columnSeparator: ',',
      // processCellCallback: (params) => {
      //   if (params.column.getColId() === 'image') {
      //     return '';
      //   }  
      //   return params.value;
      // },
    };

    gridRef.current.api.exportDataAsCsv(exportParams);
  }, []);

  useEffect(() => {
    const lastState = (JSON.parse(localStorage.getItem('grid_state')));
    if (lastState) {
      setInitialState(lastState)
    } else {
      setInitialState(
        {
          columnDefs: null,
          defaultColDef: null,
          rowData: null,
          paginationPageSize: null,
          rowSelection: null,
          suppressRowClickSelection: null,
          suppressColumnMoveAnimation: null,
          suppressExcelExport: null,
          pagination: null,
          sideBar: null,
          rowHeight: null,
          headerHeight: null,
          enableRangeSelection: null,
          enableCellTextSelection: null,
          suppressQuotes: null,
          suppressMaxRenderedRowRestriction: null,
          suppressHorizontalScroll: null,
          popupParent: null,
          frameworkComponents: null,
          statusBar: null,
          autoGroupColumnDef: null,
          enableBrowserTooltips: null,
          localeText: null,
        }
      )
    }
   
  }, [])


  return (
    <div className="ag-theme-alpine" style={{ height: '100vh', width: '100%' }}>
      <Row className='mb-3'>
        {/* <Col md={12} className='d-flex justify-content-end'>
          <Button size='small' icon="share square" content="Export to CSV" onClick={onBtnExport} color='google plus'/>
        </Col> */}
      </Row>
      <Row >
        <Col style={{ height: '65vh' }}>
          {/* <div className='mb-2' hidden={loading !== true}><Icon name='spinner' loading />Wait for a moment..</div> */}
          <Dimmer active={loading} inverted>
            <Loader content="Loading.."/>
          </Dimmer>
          {
            initialState &&
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              onGridPreDestroyed={onGridPreDestroyed}
              onStateUpdated={onStateUpdated}
              initialState={initialState}
              suppressRowClickSelection={true}
              suppressColumnMoveAnimation={true}
              suppressExcelExport={true}
              popupParent={popupParent}
              enableCellTextSelection={true}
              rowHeight={50}
            />
          }
        </Col>
      </Row>
    </div>
  );
};

export default DynamicTable;
